import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Homepage from "@/pages/homepage";
import Login from "@/pages/login.vue";
import Product from "@/pages/product/index.vue";
import Cart from "@/pages/cart/index.vue";
import store from "@/common/store";
import Confirm from "@/pages/order/confirm.vue";
import {obj2bool} from "@/common/utils";
import {checkIsLogin} from "@/common/busi/auth";
import Product_info from "@/pages/product/product_info.vue";
import catalogue from "@/pages/catalogue/index.vue";
import statement from "@/pages/statement/statement.vue";

const routes = [
    {
        path: '/',
        component: Homepage
    },
    {
        path: '/login',
        component: Login
    },
    {
        path: '/product',
        component: Product
    },
    {
        path: '/shoppingCart',
        component: Cart
    },
    {
        path: '/orderConfirm',
        component: Confirm
    },
    {
        path: '/item',
        component: Product_info
    },
    {
        path: '/catalogue',
        component: catalogue
    }, {
        path: '/statement',
        component: statement
    }
];
const mode = process.env.NODE_ENV === 'development' ? 'hash' : 'history';
const createRouter = () => new Router({
    mode, // require service support
    scrollBehavior: () => ({y: 0}),
    routes
});

const router = createRouter();

router.beforeEach(async (to, from, next) => {
    const loginFlag = uni.getStorageSync('isLogin');
    if (loginFlag) {
        // 刷新计数器
        try {
            await store.dispatch('count/getCartCount');
        } catch (e) {
            console.error(e)
        }
        const isLogin = store.getters.isLogin;
        if (!obj2bool(isLogin)) {
            try {
                const check = await checkIsLogin();
                if (check) {
                    await store.dispatch('user/setUserLogin');
                }
            } catch (e) {
                console.error(e)
            }
        }
    }
    next();
});

export default router;