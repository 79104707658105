<template>
    <div v-loading="loading">
        <hc-header :hide-search="true"/>
        <order-step1
            ref="step1"
            v-show="step === 1"
            @gotoNext="gotoStep2"
            :fcode-list="fcodeList"
        />
        <order-step2
            v-show="step === 2"
            ref="step2"
            @gotoPre="step = 1"
            :order-items="orderItems"
            @submitOrder="doSubmitOrder"
            :address="address"
            :fcode="fcode"
        />
        <order-step3 v-show="step === 3"/>
        <hc-footer/>
    </div>
</template>
<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import OrderStep1 from "@/pages/order/components/step01.vue";
import OrderStep2 from "@/pages/order/components/step02.vue";
import OrderStep3 from "@/pages/order/components/step03.vue";
import {catchHandler, isBlank} from "@/common/utils";
import {orderInit, submitOrder} from "@/api/hc/order";

export default {
    name: "order-confirm",
    components: {OrderStep3, OrderStep2, OrderStep1, HcFooter, HcHeader},
    data() {
        return {
            step: 1,
            orderItems: null,
            address: null,
            fcode: null,
            freight: null,
            fcodeList: null,
            cartIds: null,
            loading: false
        }
    },
    created() {
        const cartIds = this.$route.query.cartIds;
        if (isBlank(cartIds) || cartIds.length === 0) {
            this.$alert(this.$t('order.noChosenGoods'));
            return;
        }
        this.initOrderConfirm([].concat(cartIds))
            .catch(e => {
                catchHandler(e)
                    .then(() => {
                        this.$router.back();
                    })
                    .catch(() => {
                        this.$router.back();
                    })
            })
    },
    methods: {
        async initOrderConfirm(cartIds) {
            this.cartIds = cartIds;
            const {fcodeList, orderItems} = await orderInit(cartIds);
            this.fcodeList = fcodeList;
        },
        async gotoStep2({address, freight, fcode}) {
            this.address = address;
            this.freight = freight;
            this.fcode = fcode;
            /*if (isBlank(fcode)) {
                await this.$alert(this.$t('order.palceholder.fcode'));
            } else */
            if (isBlank(freight)) {
                await this.$alert(this.$t('order.palceholder.transType'));
            } else if (isBlank(address)) {
                await this.$alert(this.$t('order.palceholder.address'));
            } else {
                const {orderItems} = await orderInit(this.cartIds, fcode);
                this.orderItems = orderItems;
                this.step = 2;
            }
        },
        doSubmitOrder() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            // 获取地址
            const hsadicode = this.$refs.step1.getHsadicode();
            if (isBlank(hsadicode)) {
                this.$alert(this.$t('order.palceholder.address'))
                this.loading = false;
                return;
            }
            // 运输方式
            const freighttype = this.$refs.step1.getFreighttype();
            if (isBlank(freighttype)) {
                this.$alert(this.$t('order.palceholder.transType'))
                this.loading = false;
                return;
            }
            // 结算币种
            // const fcode = this.$refs.step1.getFcode();
            // if (isBlank(fcode)) {
            //     this.$alert(this.$t('order.palceholder.fcode'))
            //     return;
            // }
            const cusremark = this.$refs.step2.getRemark();
            // 构建orderInit
            const data = {
                hsadicode, freighttype, cusremark,
                orderItems: this.orderItems
            }
            submitOrder(data)
                .then(() => {
                    this.loading = false;
                    this.successHandler();
                }).catch(e => {
                this.loading = false;
                catchHandler(e)
            })
        },
        successHandler() {
            this.step = 3;
        }
    }
}
</script>

<style scoped lang="scss">

</style>