<template>
    <!-- 登录框 -->
    <div class="login-box">
        <div class="login-logo">
            <img src="@/assets/logo.png">
        </div>
        <div class="form" @keyup.enter="login">
            <div class="form-groups">
                <div class="form-item" @click="selectLang">
                    <input disabled="true" @click="selectLang" type="text" :placeholder="$t('login.placeholder.lang')"
                           v-model="form.lang"/>
                    <div class="arrow-down"></div>
                </div>
                <!--  选择语言-->
                <div class="langPanel" v-show="langpanel">
                    <p class="lang-type" :class="{cur:curIndex===index}" @click="selectCell(index)"
                       v-for="(item,index) in langList" :key="index">{{ item.name }}</p>
                </div>
            </div>
            <div class="form-groups">
                <div class="form-item">
                    <input type="text" :placeholder="$t('login.placeholder.username')" v-model="form.username"/>
                </div>
            </div>
            <div class="form-groups">
                <div class="form-item">
                    <input type="password" :placeholder="$t('login.placeholder.password')" v-model="form.password"/>
                </div>
            </div>
            <div class="form-groups">
                <div class="form-item">
                    <input type="text" :placeholder="$t('login.placeholder.checkcode')" v-model="form.checkCode"/>
                </div>
                <div class="code">
                    <img class="code-img"
                         :src="`${host}/util/AuthcodeImage.jsp?temp=${temp}`"
                         @click="resetCheckCode"
                    />
                    <i class="refresh-icon" @click="resetCheckCode"><img src="@/assets/icon/refresh.png"/></i>
                </div>
            </div>
        </div>
        <button class="submit" @click="login">{{ $t("login.login") }}</button>
    </div>
</template>
<script>
import global_config from "@/common/core/global_config";
import {authLogin} from "@/common/busi/auth";
import {catchHandler, isNotBlank} from "@/common/utils";
import Mixins from "@/common/core/mixins";
import {getMenuCfg} from "@/api/hc";

export default {
    name: "hc-login",
    mixins: [Mixins],
    data() {
        const langList = [
            {
                name: 'zh_CN:简体中文'
            }, {
                name: 'en:English'
            }
        ]
        const lang = this.$i18n.locale;
        const curIndex = lang === 'zh_CN' ? 0 : 1;
        return {
            form: {
                lang: langList[curIndex].name,
                username: null,
                password: null,
                checkCode: null
            },
            host: global_config.host,
            temp: (new Date()).getTime().toString(36),
            submitting: false,
            langpanel: false,
            curIndex,
            langList
        }
    },
    watch: {
        language(val) {
            if (val === 'zh_CN') {
                this.selectCell(0);
            } else if (val === 'en') {
                this.selectCell(1);
            }
        }
    },
    created() {
        if (process.env.NODE_ENV === 'development') {
            // this.form.username = '120';
            // this.form.password = '111111';
            // this.form.checkCode = '2222';
        }
    },
    methods: {
        login() {
            if (this.submitting === true) {
                return;
            }
            this.submitting = true;
            const language = this.langList[this.curIndex].name.split(':')[0];
            authLogin('username', {
                username: this.form.username,
                password: this.form.password,
                checkcode: this.form.checkCode,
                language
            }).then((res) => {
                this.submitting = false;
                const cuicode = res.USERCMCODE;
                if (cuicode === 'HS001') {
                    window.location.href = this.getErpHref() + '/AppMain.jsp';
                } else {
                    // 判断是否有商城菜单
                    getMenuCfg()
                        .then(({menuCfg}) => {
                            if (menuCfg && menuCfg['1010'] === 1) {
                                // 有商城菜单权限跳转商城，否则跳转ERP
                                const path = this.$route.path;
                                if (path !== '/') {
                                    this.$router.push({path: '/'});
                                }
                            } else {
                                window.location.href = this.getErpHref() + '/AppMain.jsp';
                            }
                        })
                        .catch(e => {
                            this.$router.push({path: '/'});
                        })
                }
            }).catch(e => {
                const {code, msg} = e;
                this.submitting = false;
                this.resetCheckCode();
                if (isNotBlank(msg)) {
                    if ('无效的用户名或密码错误' === msg) {
                        this.$alert(this.$t('login.nousername'))
                    } else {
                        catchHandler(e);
                    }
                } else {
                    catchHandler(e);
                }
            });
        },
        selectLang() {
            this.langpanel = !this.langpanel;
        },
        selectCell(index) {
            this.curIndex = index;
            this.form.lang = this.langList[index].name;
            this.langpanel = false;
            const language = this.langList[this.curIndex].name.split(':')[0];
            this.$i18n.locale = language;
            this.$store.dispatch('lang/setLanguage', language);
        },
        resetCheckCode() {
            this.form.checkCode = null;
            this.temp = (new Date()).getTime().toString(36);
        }
    }
}
</script>

<style scoped lang="scss">

</style>