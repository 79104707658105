import axios from 'axios';
import global_config from "@/common/core/global_config";
import i18n from "@/i18n";
import {isNotBlank} from "@/common/utils";

const baseUrl = global_config.baseUrl;

// create an axios instance
const service = axios.create({
    baseURL: '', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
});

function baseRequest({url, method = 'GET', data, header = {}, dataType, silent = false, checkResCode = true}) {
    return new Promise((resolve, reject) => {
        let requestUrl = url;
        if (!requestUrl.startsWith('http')) {
            requestUrl = `${baseUrl}${url}`;
        }
        const reqData = data;
        header['Accept'] = 'application/json';
        service.request({
            url: requestUrl,
            method: method,
            headers: header,
            data: data
        }).then(res => {
            const {status: statusCode, data} = res;
            if (data && isNotBlank(data.msg)) {
                let msg = data.msg;
                if (msg.indexOf('$t(') === 0) {
                    let s = msg.replace('$t(', '');
                    s = s.replace(")", '');
                    // s = s.substring(0, s.length - 1);
                    s = s.replaceAll("'", '')
                    console.log(s)
                    data.msg = i18n.t(s) || s;
                } else if (msg === '用户未登录') {
                    data.msg = i18n.t('noAuth') || msg;
                }
            }
            if (!checkResCode) {
                resolve(data);
                return;
            }
            if (data.code) {
                data.code = data.code * 1;
            }
            if (statusCode !== 200) {
                if (silent !== true) {
                    uni.$toast(statusCode + '')
                }
                reject({state: statusCode, res: data});
                return;
            }
            const code = data.code;
            if (Array.isArray(data)) {
                resolve(data);
                return;
            }
            if (code !== 0 && checkResCode) {
                if (silent !== true) {
                    uni.$toast(data.msg || i18n.t('request.failed'))
                }
                reject({state: 200, res: data});
                return;
            }
            resolve(data);
        }).catch(err => {
            uni.$toast(i18n.t('request.failed'))
            reject({state: 0, res: err})
        });
    })
}

const request = {
    send(options) {
        return baseRequest(options);
    },
    get(url, data, options = {}) {
        return baseRequest({
            url,
            method: 'GET',
            data,
            ...options
        })
    },
    post(url, data, options = {}) {
        return baseRequest({
            url,
            method: 'POST',
            data,
            ...options
        })
    },
    postForm(url, data, options = {}) {
        if (!options.header) {
            options.header = {};
        }
        options.header['content-type'] = 'application/x-www-form-urlencoded';
        return baseRequest({
            url,
            method: 'POST',
            data,
            ...options
        })
    }
}

export default request;