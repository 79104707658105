<template>
    <!-- 底部 -->
    <footer class="foot">
      {{ $t('index.copyright') }}
      <router-link v-if="language === 'zh_CN'" :to="{path: '/statement'}" class="statement">网站隐私声明</router-link>
      <router-link v-else :to="{path: '/statement'}" class="statement">Privacy Statement</router-link>
    </footer>
</template>
<script>
import Mixins from "@/common/core/mixins";

export default {
    name: "hc-footer",
    mixins: [Mixins]
}
</script>

<style scoped lang="scss">

</style>