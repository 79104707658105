<template>
    <div class="order">
        <div class="order-step">
            <div class="w1200">
                <ul class="step-wrap">
                    <li class="step-item cur">
                        <p class="step-num">1</p>
                        <p class="step-name">{{ $t('order.step.address') }}</p>
                    </li>
                    <li class="step-item cur">
                        <p class="step-num">2</p>
                        <p class="step-name">{{ $t('order.step.order') }}</p>
                    </li>
                    <li class="step-item">
                        <p class="step-num">3</p>
                        <p class="step-name">{{ $t('order.step.submit') }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 具体内容 -->
        <div class="step-content">
            <div class="w1200">
                <div class="step-content-item">
                    <h4 class="step-content-title">{{ $t('order.confirmOrderInfo') }}</h4>
                    <div class="cart-product-box">
                        <table class="p-table order-table">
                            <tr class="table-value"
                                v-for="(item, index) in orderItems"
                            >
                                <td class="table-value-cell product-cell" width="660">
                                    <div class="product-detail">
                                        <div class="p-table-img">
                                            <img :src="item.image || defaultPng"/>
                                        </div>
                                        <div class="p-table-name">
                                            <p class="p-name" v-if="language==='en'">{{ item.name_en }}</p>
                                            <p class="p-name" v-else>{{ item.name_cn }}</p>
                                            <p>{{ item.figurenum }}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="table-value-cell" width="120">
                                    <span class="unit-price">{{ item.upric | formatMoney(item.fcode) }}</span>
                                </td>
                                <td class="table-value-cell" width="252">
                                    <p>x<span>{{ item.qtc }}</span></p>
                                </td>
                                <td class="table-value-cell" width="120">
                                    <span class="sum-price">{{ item.fcy | formatMoney(item.fcode) }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="order-confirm">
                        <!--备注信息-->
                        <p class="remarkInfo">
                            <span class="remark-label">{{$t('remark')}}:</span>
                            <span class="remark-value">
                          <textarea class="remark-input" v-model="remark"></textarea>
                        </span>
                        </p>
                        <!-- 付款信息-->
                        <div class="realpay">
<!--                            <p class="pay-item">
                                <span class="pay-label">{{ $t('order.goodsAmount') }}：</span>
                                <span class="pay-value">{{ amount | formatMoney(fcode) }}</span>
                            </p>-->
                            <!--                        <p class="pay-item">-->
                            <!--                            <span class="pay-label">运费：</span>-->
                            <!--                            <span class="pay-value">{{ freightfcy | formatMoney('USD') }}</span>-->
                            <!--                        </p>-->
                            <p class="pay-item">
                                <span class="pay-label">{{ $t('order.totalAmount') }}：</span>
                                <span class="pay-value realpay-sum">{{ totalAmount | formatMoney(ofcode) }}</span>
                            </p>
                            <p class="pay-item" v-if="address">{{ $t('order.deliveryTo') }}：
                                <span class="pay-address">{{
                                        `${address.country} ${address.city} ${address.streetname} ${address.housenumber}`
                                    }}</span>
                                <span v-if="address.companyname">{{ address.companyname }}</span>
                                <span v-if="address.phone">{{ address.phone }}</span>
                            </p>
                            <p class="pay-item">
                                <span class="pay-label">
                                    <el-alert :title="$t('order.noFreight')" type="warning" show-icon/>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 下一步 -->
                <div class="order-bar">
                    <button class="primary-btn" @click="gotoPre">{{ $t('order.preStep') }}</button>
                    <button class="gorder" @click="submitOrder">{{ $t('order.nextStep') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {accAdd} from "@/common/utils";
import Mixins from "@/common/core/mixins";

export default {
    name: "OrderStep2",
    mixins: [Mixins],
    props: {
        orderItems: {
            type: Array,
            default() {
                return []
            }
        },
        address: {
            type: Object
        },
        fcode: {
            type: String
        }
    },
    data() {
        return {
            remark: null
        }
    },
    computed: {
        amount() {
            let amount = 0;
            if (!this.orderItems) {
                return amount;
            }
            this.orderItems.forEach(({fcy}) => {
                amount = accAdd(amount, fcy);
            });
            return amount;
        },
        freightfcy() {
            return 0;
        },
        totalAmount() {
            return accAdd(this.amount, this.freightfcy);
        },
        ofcode() {
            if (!this.orderItems || this.orderItems.length === 0) {
                return null;
            }
            return this.orderItems[0].fcode;
        }
    },
    methods: {
        gotoPre() {
            this.$emit('gotoPre');
        },
        submitOrder() {
            this.$emit('submitOrder');
        },
        getRemark() {
            return this.remark;
        }
    }
}
</script>
<style scoped>
page {
    background: #f1f1f1;
}
</style>