export default {
    companyName: 'HC MANUFACTURER',
    slogan: 'Specialized production, brand management, group operation, international integration',
    homepage: 'Home',
    messageCenter: 'Message Center',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    confirm: 'Confirm',
    remark: 'Remark',
    request: {
        failed: 'Request Failed'
    },
    index: {
        recommend: 'Special Offer',
        slogan: 'HangChaGroup R&d and manufacturing',
        adv: 'Professional production, quality management, group operation, international integration',
        chinaMI: 'Chinese manufacturing industry',
        devHistory: 'Development history',
        holdingSubsidiary: 'Holding subsidiary',
        overseasDistributor: 'Overseas distributor',
        group: 'Group',
        announcement: 'Announcement',
        more: 'View More',
        contactPhone: 'National service hotline',
        top: 'Top',
        pcs: 'PCs',
        copyright: 'Copyright©2024 All Reserved HangCha Group Co., LTD',
        desc1: 'HANGCHA is proud on its history and developments since it was founded in 1956. Initially as a state-owned company but transformed into a privately owned company in 2000. In the 60 years history HANGCHA has, thanks to the hard work and support of all employees, determination of management and our continuous innovation efforts, developed from a small company into one of the leading global material handling equipment manufacturers in China and globally.',
        desc2: 'In China, HANGCHA has gained recognition as a successful company. It is on the list of the 1000 ‘Biggest Enterprises’ , in the top 500 ‘Most Competitive’ and in the top 500 ‘Biggest Private Enterprises’, proving HANGCHA’s direction has been right.',
        desc3:'Currently we manufacture annually more than 250,000 units of forklift trucks and warehouse equipment in our state-of-the-art factory in Li-an, Zhejiang province. And we are expanding production capacity further in order to meet the expected growing demand for our products in the coming years.',
        desc4:'We shall push further ahead in our mission to be a supplier of high quality material handling\n' +
            'equipment, which supports all our customers, both in China and all other countries around the world, with their materials handling processes. We shall sincerely look for\n' +
            'cooperation with our customers and our partners for a mutually brilliant future.\n' +
            'Hangcha – make handling easier!',
        searchParts: 'Search by Parts Number',
        search: 'Find my part',
        searchPlaceHolder: 'Enter part or reference number'
    },
    cart: {
        name: 'Shopping cart',
        add: 'Add to shopping cart',
        addSuccess: 'Add successfully!',
        checkAll: 'Select all',
        goodsInfo: 'Product information',
        unitPrice: 'Unit price',
        num: 'Quantity',
        operate: 'operate',
        replaceTime: 'Estimated replenishment time',
        delete: 'Delete',
        invalidLength: ' Invalid goods',
        clear: 'Clear',
        invalid: 'Invalid',
        clearInvalid: 'Removal of invalid product',
        total: 'Total',
        order: 'Place an order',
        chosenToDelete: 'Please select the item you want to delete!',
        confirmToDelete: 'Are you sure you want to delete the selected item?',
        placeChosenToBy: 'Please check the item you want to buy!',
        productNotExist: 'Item does not exist or has been removed from sale',
        cartGoodsNotExist: 'Shopping cart item does not exist!',
        chosenHasInvalid: 'There are invalid items in the selected items, please re-select!',
        importByExcel: 'Import from Excel',
        totalImportError: 'Failed to import a total of {0} data!',
        showAll: 'Show All',
        showOnlyFailed: 'Show Failed',
        no: 'No.',
        errorMessage: 'Error Message',
        downloadTemplate: 'Download Template',
        dropFileHere: 'Drag the file here, or',
        click2upload: 'Click to upload',
        uploadTip: 'Only xls files can be uploaded',
        materialCode: 'Material Code'
    },
    product: {
        center: 'Spare Parts',
        search: 'Search for Products',
        code: 'Material number',
        goodsInfo: 'Commodity Information',
        figurenum: 'Part Number',
        stock: 'Inventory',
        title: 'Product Series Display',
        advantage: 'First-class R & D strength first-class production equipment first-class testing equipment first-class quality control level',
        estarridate: 'Estimated Time of Delivery',
        login2view: 'Please login to view the quote',
        day: 'Day',
    },
    partCatalogues: {
        name: "Part Catalogues"
    },
    login: {
        login: 'Login',
        placeholder: {
            username: 'Please enter the user ID/mobile phone number/email',
            password: 'Please enter password',
            checkcode: 'Please enter the verification code'
        },
        nousername: 'Invalid username or password',
        logout: 'Logout'
    },
    address: {
        title: 'Delivery Address',
        companyName: 'Company Name',
        streetname: 'Street Name',
        housenumber: 'House Number',
        zipcode: 'Zip Code',
        city: 'City',
        country: 'Country/Region',
        setAsDefault: 'Set it to the default address',
        setDefault: 'Set default',
        useNew: 'Use new address',
        notExist: 'The address does not exist or has been deleted!'
    },
    order: {
        step: {
            address: 'Fill in address information',
            order: 'Confirm order information',
            submit: 'Submit Order'
        },
        nextStep: 'Next Step',
        preStep: 'Last Step',
        confirmOrderInfo: 'Confirm order information',
        goodsAmount: 'Gross merchandise value',
        actualPay: 'Actual Payment',
        totalAmount: 'Total Amount',
        deliveryTo: 'Send To',
        submitSuccess: 'Thank you for placing the order,our customer manager will contact with you by email as soon as possible',
        viewOrder: 'View Order',
        transType: {
            name: 'Mode of transport',
            sea: 'By Sea',
            air: 'By Air',
            express: 'By Express',
            dhl: 'By DHL',
            fedex: 'By Fedex',
            forklifts: 'With Forklifts'
        },
        payFcode: 'Settlement Currency',
        noChosenGoods: 'Did not load the selected item!',
        palceholder: {
            fcode: 'Please select a settlement currency!',
            transType: 'Please select a shipping method!',
            address: 'Please select a delivery address!'
        },
        noFreight: 'Freight will be offered seperately depending on shipping method you choose!'
    },
    pagination: {
        total: 'Total ',
        page: '',
        goto: 'Go To'
    },
    catalogue: {
        slogan: 'Here you can find all our documents',
        placeholder: 'Enter your machine model',
        search: 'Search',
        login2search: 'Please login for search'
    }
}