<template>
    <div class="step-content-item">
        <h4 class="step-content-title">{{ $t('address.title') }}</h4>
        <div class="common-addr">
            <div class="addr-item"
                 v-for="(item, idx) in list"
                 :key="idx"
                 :class="item.hsadicode===hsadicode ? 'active': ''"
                 @click="changeActive(item)"
            >
                <input type="radio" class="radio-input" name="address" :checked="item.hsadicode===hsadicode"/>
                <div class="addr-box">
                    <p class="addr-detail">
                        <span>{{ item.companyname || ''}}</span>
                        <span>{{ `${item.country || ''} ${item.city|| ''}` }}</span>
                        <span>{{ `${item.streetname|| ''} ${item.housenumber|| ''}` }}</span>
                    </p>
                    <p class="operations">
                        <a v-if="item.dftaddr!= 1" @click="setDefaultAddress(item)">{{ $t('address.setDefault') }}</a>
                        <a @click="editAddress(item)">{{ $t('edit') }}</a>
                        <a @click="deleteAddress(item)">{{ $t('delete') }}</a>
                    </p>
                </div>
            </div>
        </div>
        <AddressInput ref="addrInput" @addressUpdated="addressUpdated"/>
    </div>
</template>
<script>
import AddressInput from "@/pages/order/components/address-input.vue";
import {addressDelete, addressList, setDefault} from "@/api/hc/address";

export default {
    name: "mail-address",
    components: {AddressInput},
    data() {
        return {
            list: [],
            inputShow: false,
            addressForm: {},
            hsadicode: null
        }
    },
    created() {
        this.loadAddressList();
    },
    methods: {
        loadAddressList() {
            addressList()
                .then(({list}) => {
                    this.renderList(list);
                })
        },
        renderList(list) {
            if (list.length > 0) {
                this.hsadicode = list[0].hsadicode
            }
            this.list = list;
        },
        setDefaultAddress(address) {
            setDefault(address)
                .then(() => {
                    this.loadAddressList();
                });
        },
        editAddress(address) {
            this.$refs.addrInput.showInput(address);
        },
        deleteAddress(address) {
            addressDelete(address)
                .then(() => {
                    this.loadAddressList();
                });
        },
        changeActive(address) {
            this.hsadicode = address.hsadicode
        },
        getHsadicode() {
            return this.hsadicode;
        },
        getChosenAddress() {
            for (let i = 0; i < this.list.length; i++) {
                const {hsadicode} = this.list[i];
                if (hsadicode === this.hsadicode) {
                    return this.list[i];
                }
            }
            return null;
        },
        addressUpdated() {
            this.loadAddressList();
        }
    }
}
</script>

<style scoped lang="scss">

</style>