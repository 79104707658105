<template>
    <ol class="count">
        <li class="minus" @click="minModelValue">-</li>
        <li class="num">
            <input type="text" v-model="modelValue" @change="inputChange"/>
        </li>
        <li class="addto" @click="addModelValue">+</li>
    </ol>
</template>
<script>
import {accAdd, accSub, isNotBlank} from "@/common/utils";

export default {
    name: "sn-number-box",
    props: {
        min: {
            type: Number,
            default() {
                return Number.MAX_SAFE_INTEGER;
            }
        },
        max: {
            type: Number,
            default() {
                return Number.MAX_SAFE_INTEGER;
            }
        },
        value: {
            default() {
                return null;
            }
        },
        integer: {
            default() {
                return false;
            }
        },
        decimalLength: {
            default() {
                return 0;
            }
        }
    },
    data() {
        return {
            modelValue: null
        }
    },
    mounted() {
        this.init();
    },
    watch: {
        value(n) {
            if (n !== this.modelValue) {
                this.modelValue = this.value;
            }
        }
    },
    methods: {
        init() {
            this.modelValue = this.formatValue(this.value);
        },
        inputChange() {
            const fmt = this.formatValue(this.modelValue);
            this.$emit('input', fmt);
            this.$emit('change', fmt);
        },
        addModelValue() {
            this.modelValue = this.formatValue(accAdd(this.modelValue, 1));
            this.inputChange();
        },
        minModelValue() {
            this.modelValue = this.formatValue(accSub(this.modelValue, 1));
            this.inputChange();
        },
        formatValue(value) {
            value = this.filter(value)
            // 如果为空字符串，那么设置为0，同时将值转为Number类型
            value = value === '' ? 0 : +value
            // 对比最大最小值，取在min和max之间的值
            value = Math.max(Math.min(this.max, value), this.min)
            // 如果设定了最大的小数位数，使用toFixed去进行格式化
            if (isNotBlank(this.decimalLength)) {
                value = value.toFixed(this.decimalLength)
            }
            return value
        },
        filter(value) {
            // 只允许0-9之间的数字，"."为小数点，"-"为负数时候使用
            value = String(value).replace(/[^0-9.-]/g, '')
            // 如果只允许输入整数，则过滤掉小数点后的部分
            if (this.integer && value.indexOf('.') !== -1) {
                value = value.split('.')[0]
            }
            return value;
        }
    }
}
</script>

<style scoped lang="scss">

</style>