import {getUserInfo} from "@/api/core/auth";
import {isNotBlank} from "@/common/utils";
const User = {
    setUserInfo: 'SET_USER_INFO',
    setUserLogin: 'SET_USER_LOGIN',
    userLogout: 'USER_LOG_OUT'
}
const state = {
    username: null,
    usercode: null,
    name: null,
    vfemail: null,
    vfmbintlno: null,
    vfmobile: null,
    isAdmin: false,
    isLogin: false,
    env: {}
};

const mutations = {
    [User.setUserInfo]: (state, { username, name, vfemail, vfmbintlno, vfmobile, admin, env, usercode }) => {
        state.username = username;
        state.usercode= usercode;
        state.name = name;
        state.vfemail = vfemail;
        state.vfmbintlno = vfmbintlno;
        state.vfmobile = vfmobile;
        state.isAdmin = admin;
        if (env) {
            let bcode = env.bcode;
            if (isNotBlank(bcode) && bcode.indexOf(',') > 0) {
                bcode = bcode.split(',')[0];
                env.bcode = bcode;
            }
        }
        state.env = env;
    },
    [User.setUserLogin]: (state) => {
        state.isLogin = true;
    },
    [User.userLogout]: (state) => {
        state.username = null;
        state.usercode = null;
        state.name = null;
        state.vfemail = null;
        state.vfmbintlno = null;
        state.vfmobile = null;
        state.isAdmin = null;
        state.isLogin = false;
    }
};

const actions = {
    setUserInfo({commit}, userInfo) {
        if (userInfo) {
            commit(User.setUserInfo, userInfo);
        } else {
            getUserInfo().then(({userInfo})=> {
                commit(User.setUserInfo, userInfo);
            });
        }
    },
    setUserLogin({commit}, userInfo) {
        uni.setStorageSync('isLogin', true);
        commit(User.setUserLogin);
        if (userInfo) {
            commit(User.setUserInfo, userInfo);
        } else {
            getUserInfo().then(({userInfo})=> {
                commit(User.setUserInfo, userInfo);
            });
        }
    },
    logout({ commit }) {
        commit(User.userLogout);
        uni.removeStorageSync('isLogin');
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
