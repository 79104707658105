import global_config from "@/common/core/global_config";

export default {
    data() {
        return {
            defaultPng: require('@/assets/default.png'),
        }
    },
    computed: {
        isLogin() {
            return this.$store.getters.isLogin;
        },
        user() {
            return this.$store.getters.user;
        },
        env() {
            return this.$store.getters.env;
        },
        wsid() {
            return this.$store.getters.wsid;
        },
        cartCount() {
            return this.$store.getters.cartCount;
        },
        messageCount() {
            return this.$store.getters.messageCount;
        },
        language() {
            return this.$store.getters.language;
        }
    },
    methods: {
        getErpHref() {
            if (process.env.NODE_ENV === 'development') {
                const proxyConfig = require('@/../proxy_config')
                return `${proxyConfig.REST_PROTOCOL}://${proxyConfig.REST_HOSTNAME}:${proxyConfig.REST_PORT}/${proxyConfig.REST_PATH}`;
            } else {
                return global_config.host;
            }
        }
    }
}