<template>
    <div class="hc" style="background: #f1f1f1">
        <!-- 头部 -->
        <hc-header/>
        <div class="content">
            <div class="cart">
                <div class="cart-detail">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <h4 class="cart-title">{{ $t('cart.name') }}（<span>{{ cartCount }}</span>）</h4>
                        <el-button type="text" @click="dialogTableVisible=true">{{ $t('cart.importByExcel') }}</el-button>
                    </div>
                    <div class="cart-product-box">
                        <table class="p-table">
                            <tr class="table-label">
                                <th class="all-checkbox" width="110">
                                    <label class="custom-checkbox">
                                        <input type="checkbox" class="checkbox-input" v-model="allChecked"
                                               @click="checkAll"/>
                                        <span class="checkmark"></span>
                                    </label>
                                    <span>{{ $t('cart.checkAll') }}</span>
                                </th>
                                <th width="660" style="text-align: left;"><span>{{ $t('cart.goodsInfo') }}</span></th>
                                <th width="112"><span>{{ $t('cart.unitPrice') }}</span></th>
                                <th width="190"><span>{{ $t('cart.num') }}</span></th>
                                <th width="80"><span>{{ $t('cart.operate') }}</span></th>
                            </tr>
                            <tr class="table-value"
                                v-for="(item, index) in list"
                                :key="index"
                            >
                                <td class="table-value-cell">
                                    <label class="custom-checkbox">
                                        <input type="checkbox" class="checkbox-input" v-model="item.checked"
                                               @change="pickChange(index)"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="table-value-cell">
                                    <div class="product-detail">
                                        <div class="p-table-img">
                                            <img :src="item.image || defaultPng"/>
                                        </div>
                                        <span>
                                        {{ item.langname1 }}
                                        <br>
                                        {{ item.figurenum }}<br>
                                        {{
                                                item.actuallibno ? (item.actuallibno < item.qtc) ?
                                                    (item.estarridate ? $t('cart.replaceTime') + VF().formatDate(item.estarridate) : '')
                                                    : '' : ''
                                            }}
                                    </span>
                                    </div>
                                </td>
                                <td class="table-value-cell">
                                    <span class="unit-price">{{ item.upric | formatMoney(item.fcode) }}</span>
                                </td>
                                <td class="table-value-cell">
                                    <sn-number-box v-model="item.qtc" :min="1" @change="numChange(index)"/>
                                </td>
                                <td class="table-value-cell">
                                    <a class="delete" @click="toDeleteCart(index)">{{ $t('cart.delete') }}</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- 失效商品 -->
                <div class="lose" v-if="invalidList.length > 0">
                    <h6 class="lose-title">
                        <p><span>{{ invalidList.length }}</span>{{ $t('cart.invalidLength') }}</p>
                        <a class="empty">{{ $t('cart.clear') }}</a>
                    </h6>
                    <div class="cart-product-box">
                        <table class="p-table">
                            <tr class="table-value"
                                v-for="(item, idx) in invalidList"
                            >
                                <td class="table-value-cell" width="110">
                                    <label class="custom-checkbox">
                                        <input type="checkbox" disabled class="checkbox-input"/>
                                        <span class="checkmark"></span>
                                    </label>
                                </td>
                                <td class="table-value-cell" width="660">
                                    <div class="product-detail">
                                        <div class="p-table-img">
                                            <div class="mask">{{ $t('cart.invalid') }}</div>
                                            <img :src="item.image"/>
                                        </div>
                                        <span>{{ item.langname1 }}</span>
                                    </div>
                                </td>
                                <td class="table-value-cell" width="112">
                                    <span class="unit-price">{{ item.upric | formatMoney(item.fcode) }}</span>
                                </td>
                                <td class="table-value-cell" width="190">
                                    <ol class="count">
                                        <li class="minus">-</li>
                                        <li class="num">
                                            <input type="text" value="1" v-model="item.num"/>
                                        </li>
                                        <li class="addto">+</li>
                                    </ol>
                                </td>
                                <td class="table-value-cell" width="80">
                                    <a class="delete">{{ $t('cart.delete') }}</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- 结算 -->
                <div class="cart-footer">
                    <div class="footer-bar">
                        <div class="footer-left">
                            <div class="all-checkbox">
                                <label class="custom-checkbox">
                                    <input type="checkbox" class="checkbox-input" v-model="allChecked"
                                           @click="checkAll"/>
                                    <span class="checkmark"></span>
                                </label>
                                <span>{{ $t('cart.checkAll') }}</span>
                            </div>
                            <ul class="operate">
                                <li class="operate-item" @click="toDeleteCart()"><a>{{ $t('cart.delete') }}</a></li>
                                <li class="operate-item" @click="removeInvalid"><a>{{ $t('cart.clearInvalid') }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="footer-right">
                            <p v-if="language==='zh_CN'" class="select-num">已选<span class="key">{{ checkProduct }}</span>种商品，共<span
                                class="key">{{ checkNum }}</span>件</p>
                            <p v-else-if="language==='en'" class="select-num">
                                <span class="key">{{ checkProduct }}</span> Products&nbsp;&nbsp;
                                Total Piece<span class="key">{{ checkNum }}</span>
                            </p>
                            <p class="amount">{{ $t('cart.total') }} <span class="key">{{
                                    total | formatMoney('USD')
                                }}</span></p>
                            <button class="gorder" @click="buildOrder">{{ $t('cart.order') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部  -->
        <hc-footer/>
        <el-dialog :title="$t('cart.importByExcel')" :visible.sync="dialogTableVisible" :center="true">
            <div style="display: flex; justify-content: center; flex-direction: column; align-items: center">
                <el-upload
                    class="upload-demo"
                    drag
                    :action="excelImportUrl"
                    name="excelFile"
                    :on-success="importHandler"
                    ref="uploader"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">{{$t('cart.dropFileHere')}} <em>{{ $t('cart.click2upload') }}</em></div>
                    <div class="el-upload__tip" slot="tip">{{ $t('cart.uploadTip') }}</div>
                </el-upload>
                <el-button @click.native="downloadTemplate" type="text" >{{ $t('cart.downloadTemplate') }}</el-button>
            </div>
        </el-dialog>
        <cart-import-res ref="importRes" />
    </div>
</template>
<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import { cartList, changeCartNum, checkCart, deleteCart, removeInvalid} from "@/api/hc/cart";
import {accAdd, accMul, isBlank, isNotBlank, obj2bool, obj2int} from "@/common/utils";
import SnNumberBox from "@/components/common/numberbox/sn-number-box.vue";
import VF from "../../common/core/valueFormatter";
import Mixins from "@/common/core/mixins";
import global_config from "@/common/core/global_config";
import CartImportRes from "@/pages/cart/cart-import-res.vue";

export default {
    name: '',
    components: {CartImportRes, SnNumberBox, HcHeader, HcFooter},
    mixins: [Mixins],
    created() {
        this.loadData();
    },
    data() {
        return {
            list: [],
            invalidList: [],
            allChecked: null,
            dialogTableVisible: false
        }
    },
    computed: {
        checkNum() {
            let total = 0;
            this.list.forEach(({checked, qtc}) => {
                if (obj2bool(checked)) {
                    total = accAdd(total, qtc);
                }
            });
            return total;
        },
        checkProduct() {
            let total = 0;
            this.list.forEach(({checked, qtc}) => {
                if (obj2bool(checked)) {
                    total += 1;
                }
            });
            return total;
        },
        total() {
            let total = 0;
            this.list.forEach(({checked, qtc, upric}) => {
                if (obj2bool(checked)) {
                    total = accAdd(total, accMul(upric, qtc));
                }
            });
            return total;
        },
        excelImportUrl() {
            const baseUrl = global_config.baseUrl;
            return `${baseUrl}/mall/cart/excelImport`
        }
    },
    methods: {
        VF() {
            return VF
        },
        loadData() {
            cartList()
                .then(({list}) => {
                    this.renderList(list);
                });
        },
        renderList(list) {
            const activeList = [], invalidList = [];
            let allChecked = true;
            list.forEach(item => {
                if (obj2bool(item.invalid)) {
                    invalidList.push(item);
                } else {
                    if (!obj2bool(item.checked)) {
                        allChecked = false;
                    }
                    activeList.push(item);
                }
            });
            this.allChecked = allChecked;
            this.list = activeList;
            this.invalidList = invalidList;
        },
        pickChange(idx) {
            this.$nextTick(() => {
                const {hsscicode, checked} = this.list[idx];
                checkCart([hsscicode], checked);
            });
        },
        checkAll() {
            const checked = !this.allChecked;
            this.list.forEach(item => {
                item.checked = checked;
            });
            this.allChecked = checked;
            this.$nextTick(() => {
                checkCart(null, checked);
            });
        },
        numChange(idx) {
            this.$nextTick(() => {
                const {hsscicode, qtc} = this.list[idx];
                changeCartNum(hsscicode, qtc)
                    .then(() => {
                    })
            });
        },
        removeInvalid() {
            removeInvalid()
                .then(() => {

                })
        },
        toDeleteCart(idx) {
            const ids = [];
            if (isNotBlank(idx)) {
                ids.push(this.list[idx].hsscicode);
            } else {
                this.list.forEach(item => {
                    if (item.checked) {
                        ids.push(item.hsscicode);
                    }
                });
            }
            if (ids.length === 0) {
                this.$alert(this.$t('cart.chosenToDelete'))
            } else {
                this.$confirm(this.$t('cart.confirmToDelete'))
                    .then(() => {
                        this.doDeleteCart(ids);
                    });
            }
        },
        doDeleteCart(ids) {
            deleteCart(ids)
                .then(() => {
                    this.loadData();
                    this.$store.dispatch('count/getCartCount');
                })
        },
        buildOrder() {
            const cartIds = [];
            this.list.forEach(({hsscicode, checked}) => {
                if (obj2bool(checked)) {
                    cartIds.push(hsscicode);
                }
            });
            if (cartIds.length === 0) {
                this.$toast(this.$t('cart.placeChosenToBy'))
                return;
            }
            this.$router.push({
                path: '/orderConfirm',
                query: {
                    cartIds
                }
            })
        },
        importHandler(response) {
            const {code, msg, xlsData, filePath} = response;
            if (code !== 0) {
                this.$refs.uploader.clearFiles();
                /*if (filePath) {
                    const url = `${this.getErpHref()}/rest/mall/cart/errxls/${filePath}`
                    window.open(url)
                }*/
                if (Array.isArray(xlsData)) {
                    this.$refs.importRes.show(xlsData);
                } else {
                    this.$alert(msg);
                }
            } else {
                this.$refs.uploader.clearFiles();
                this.dialogTableVisible = false;
                this.loadData();
            }
        },
        downloadTemplate() {
            const url = `${window.location.origin}/res/${this.$i18n.locale === 'en' ? 'ShoppingCartImportTemplate.xls' : '购物车导入模板.xls'}?t=${new Date().getTime()}`;
            window.open(url);
        }
    }
}
</script>
<style lang="scss">
 page{
   background: #f1f1f1;
 }

</style>
<style lang="scss" scoped>
.el-button--text{
  color: #333;
  //background: #065d92;
  padding: 8px 12px;
  font-size: 13px;
  border: 1px solid #d9d9d9;
}
.el-button--text:focus, .el-button--text:hover{
  border-color: #065d92;
  color: #065d92;
}
.el-upload-dragger .el-upload__text em{
  color: #065d92;
}

</style>
<style lang="scss">
.el-upload-dragger:hover{
  border-color: #065d92;
}
</style>