const getters = {
    isLogin: state => state.user.isLogin,
    user: state => state.user,
    env: state => state.user.env,
    wsid: state => state.user.env.idworkspc,
    cartCount: state => state.count.cart,
    messageCount: state => state.count.message,
    language: state => state.lang.language
};

export default getters;
