<template>
    <header class="head">
        <div class="head-side">
            <div class="head-left">
                <!--                <img class="logo" src="@/assets/logo.png"/>-->
            </div>
            <ul class="head-mid">
                <li class="menu-item">
                    <router-link :to="{path: '/'}">
                        <p class="menu-ch">{{ $t('homepage') }}</p>
                        <!--                        <p class="menu-en">HOME</p>-->
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link :to="{path: '/product'}">
                        <p class="menu-ch">{{ $t('product.center') }}</p>
                        <!--                        <p class="menu-en">PRODUCT</p>-->
                    </router-link>
                </li>
                <li class="menu-item">
                    <router-link :to="{path: '/catalogue'}">
                        <p class="menu-ch">{{ $t('partCatalogues.name') }}</p>
                        <!--                        <p class="menu-en">PRODUCT</p>-->
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="head-right">
            <!--搜索框-->
            <div class="search" v-if="!hideSearch" @keyup.enter="keywordChange">
                <input class="search-input" type="text" :placeholder="$t('product.search')" @keydown.enter="keywordChange"
                       v-model="keyword"/>
                <i class="iconfont search-icon" @click="keywordChange">&#xe747;</i>
            </div>
            <ol class="r-content">
                <li class="r-item" v-if="isLogin">
                    <router-link :to="{path: '/shoppingCart'}" class="icons">
                        <i class="iconfont">&#xe748;</i>
                        <span class="tip-num">{{ cartCount }}</span>
                    </router-link>
                </li>
                <li class="r-item" @click="changeLanguage">
                    <i class="iconfont lang-icon">&#xe745;</i>
                    <div class="lang-text">
                        <span>CN</span>/
                        <span>EN</span></div>
                </li>
                <li class="r-item" v-if="isLogin" @click="messageCenter">
                    <div class="icons">
                        <span class="tip-dot" v-if="messageCount"></span>
                        <i class="iconfont news-icon">&#xe742;</i>
                    </div>
                    <span class="r-item-desc">{{ $t('messageCenter') }}</span>
                </li>
                <li class="r-item">
                    <div class="login-btn" v-if="!isLogin">
                        <i class="iconfont user-icon">&#xe744;</i>
                        <router-link :to="{path: '/login'}" class="user-name">{{ $t('login.login') }}</router-link>
                    </div>
                    <div class="login-btn" v-else @click="openCenter">
                        <i class="iconfont user-icon">&#xe744;</i>
                        <span class="user-name">{{ user.name }}</span>
                    </div>
                </li>
                <li class="r-item" v-if="isLogin">
                    <div class="login-btn" @click="logout">
                        <i class="iconfont user-icon">&#xe74a;</i>
                        <!--                        <router-link :to="{path: '/login'}" class="user-name">{{ $t('login.logout') }}</router-link>-->
                    </div>
                </li>
            </ol>
        </div>
    </header>
</template>
<script>
import Mixins from "@/common/core/mixins";
import {isBlank, isNotBlank} from "@/common/utils";
import {getLoginPageInfo, logout} from "@/api/core/auth";
import locale from 'element-ui/lib/locale';
import localeEn from 'element-ui/lib/locale/lang/en';
import localeCN from 'element-ui/lib/locale/lang/zh-CN'

export default {
    name: "hc-header",
    mixins: [Mixins],
    data() {
        return {
            lastTimeout: null,
            keyword: null
        }
    },
    props: {
        hideSearch: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    mounted() {
        this.$store.dispatch('count/getCartCount');
        setTimeout(() => {
            this.$store.dispatch('count/getMessageCount', {wsid: this.wsid, lang: this.language});
        }, 1000)
    },
    created() {
        const {keyword} = this.$route.query;
        if (isNotBlank(keyword)) {
            this.keyword = decodeURIComponent(keyword);
        }
    },
    methods: {
        keywordChange(e) {
            let v = this.keyword;
            if (isNotBlank(v)) {
                v = v.toUpperCase();
            }
            if (this.lastTimeout) {
                clearTimeout(this.lastTimeout);
            }
            this.lastTimeout = setTimeout(() => {
                let url = `${window.origin}/product`
                if (isNotBlank(v)) {
                    url = '/product?keyword=' + encodeURIComponent(v);
                }
                window.location.href = url;
                /*this.$router.push({
                    path: '/product',
                    query: {
                        keyword: encodeURIComponent(v)
                    }
                })*/
            }, 10);
        },
        openCenter() {
            window.open(this.getErpHref() + '/AppMain.jsp');
        },
        async messageCenter() {
            const href = this.getErpHref();
            let wsid = this.env.idworkspc;
            const lang = this.$i18n.locale;
            if (isBlank(wsid)) {
                const res = await getLoginPageInfo();
                wsid = res.IDWORKSPC;
            }
            window.open(`${href}/uiinvoke/${wsid}/${lang}/theme1/27.02.08.90.10.html`)
        },
        changeLanguage() {
            let lang = null;
            if (this.language === 'zh_CN') {
                lang = 'en';
            } else {
                lang = 'zh_CN';
            }
            this.$i18n.locale = lang;
            this.$store.dispatch('lang/setLanguage', lang);
            locale.use(lang === 'en' ? localeEn : localeCN);
        },
        logout() {
            logout()
                .then(() => {
                    this.$store.dispatch('user/logout')
                        .then(() => {
                            const path = this.$route.path;
                            if (path !== '/') {
                                this.$router.push({path: '/'});
                            } else {
                                window.location.reload();
                            }
                        }).catch(e => window.location.reload());

                })
        }
    }
}
</script>

<style scoped lang="scss">

</style>