const Language = {
    setLanguage: 'SET_LANGUAGE'
}

const state = {
    language: null
}

const mutations = {
    [Language.setLanguage]: (state, language) => {
        state.language = language
    }
}

const actions = {
    async setLanguage({state, commit}, language) {
        localStorage.setItem('language',language);
        commit(Language.setLanguage, language);
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
