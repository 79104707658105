import VueI18n from 'vue-i18n'
import Vue from "vue";
import {isBlank} from "@/common/utils";

const zh_CN = require('./zh_CN');
const en_US = require('./en_US');

const message = {
    zh_CN: zh_CN.default, en: en_US.default
}

Vue.use(VueI18n);

let language = localStorage.getItem('language');
if (isBlank(language)) {
    const navLang = navigator.language;
    if (navLang.includes('zh')) {
        language = 'zh_CN';
    } else {
        language = 'en';
    }
}
const i18n = new VueI18n({
    messages: {
        ...message
    },
    locale: language
});

export default i18n