<template>
    <div class="fixed-side-nav">
        <div class="side-item">
            <i class="iconfont">&#xe746;</i>
            <div class="service">
                <div class="side-title">
                    <i class="iconfont tel-icon">&#xe743;</i>
                    <span class="side-name">{{ $t('index.contactPhone') }}</span>
                </div>
                <p class="service-value">4008-777-200</p>
            </div>
        </div>
        <router-link :to="{path: '/shoppingCart'}" class="side-item" v-if="isLogin">
            <div class="icons">
                <i class="iconfont">&#xe748;</i>
                <span class="tip-num" v-if="cartCount!=0">{{ cartCount }}</span>
            </div>
        </router-link>
    </div>
</template>
<script>
import Mixins from "@/common/core/mixins";

export default {
    name: "hc-sidenav",
    mixins: [Mixins]
}
</script>
<style scoped lang="scss">

</style>