<template>
    <div class="order">
        <div class="order-step">
            <div class="w1200">
                <ul class="step-wrap">
                    <li class="step-item cur">
                        <p class="step-num">1</p>
                        <p class="step-name">{{ $t('order.step.address') }}</p>
                    </li>
                    <li class="step-item cur">
                        <p class="step-num">2</p>
                        <p class="step-name">{{ $t('order.step.order') }}</p>
                    </li>
                    <li class="step-item cur">
                        <p class="step-num">3</p>
                        <p class="step-name">{{ $t('order.step.submit') }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <!--  具体内容-->
        <div class="step-content"></div>
        <div class="w1200">
            <div class="sucess">
                <div class="sucessTip"></div>
                <p class="tip-text">{{ $t('order.submitSuccess') }}！</p>
                <button class="gorder" @click="orderCenter">{{ $t('order.viewOrder') }}</button>
            </div>
        </div>

    </div>
</template>
<script>
import Mixins from "@/common/core/mixins";

export default {
    name: "OrderStep3",
    mixins: [Mixins],
    methods: {
        orderCenter() {
            const href = this.getErpHref();
            const wsid = this.env.idworkspc;
            const lang = this.env.language;
            // window.location.href = `${href}/uiinvoke/${wsid}/${lang}/theme1/5031.HC.502.20.30.11.html?AutoRefresh=1`;
            // window.location.replace(`${href}/uiinvoke/${wsid}/${lang}/theme1/5031.HC.502.20.30.11.html?AutoRefresh=1`);
            window.location.replace(`${href}/AppMain.jsp?OPMUIID=5031.HC.502.20.30.11`);
        }
    }
}
</script>


<style scoped lang="scss">

</style>
