import request from "@/common/core/request";

export function addressInput(address) {
    return request.post("/mall/address/input", address)
}

export function addressList() {
    return request.get('/mall/address/list', {})
}

export function setDefault(address) {
    return request.post('/mall/address/default', address)
}

export function addressDelete(item) {
    return request.post('/mall/address/delete', item)
}