import { render, staticRenderFns } from "./trans-type.vue?vue&type=template&id=24dd407c&scoped=true"
import script from "./trans-type.vue?vue&type=script&lang=js"
export * from "./trans-type.vue?vue&type=script&lang=js"
import style0 from "./trans-type.vue?vue&type=style&index=0&id=24dd407c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24dd407c",
  null
  
)

export default component.exports