// import parseTime, formatTime and set to filter
import valueFormatter from "@/common/core/valueFormatter";
import VF from "@/common/core/valueFormatter";

export {parseTime, formatTime} from '@/common/utils';

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
    if (time === 1) {
        return time + label;
    }
    return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
    const between = Date.now() / 1000 - Number(time);
    if (between < 3600) {
        return pluralize(~~(between / 60), ' minute');
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), ' hour');
    } else {
        return pluralize(~~(between / 86400), ' day');
    }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
    const si = [
        {value: 1E18, symbol: 'E'},
        {value: 1E15, symbol: 'P'},
        {value: 1E12, symbol: 'T'},
        {value: 1E9, symbol: 'G'},
        {value: 1E6, symbol: 'M'},
        {value: 1E3, symbol: 'k'}
    ];
    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
        }
    }
    return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
    return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 隐藏敏感信息
 * @param string 源字符串
 * @param start 起始坐标
 * @param len 加*位数
 */
export function hideSst(string, start = 0, len = 3) {
    if (!string) {
        return string;
    }
    const lengt = string.length;
    if (start >= lengt) {
        return string;
    }
    if (lengt < (start + 1 + len)) {
        len = lengt - (start + 1);
    }
    let fill = "";
    for (let i = 0; i < len; i++) {
        fill += "*";
    }
    string = string.substr(0, (start - 1)) + fill + string.substr(((start - 1) + len));
    return string;
}

/**
 * 格式化手机号码，
 * @param str 手机号码
 * @param type 1, 只留号码, 2 +86 形式
 * @param hSst
 * @returns {*}
 */
export function fmtMobile(str, type = 1, hSst = false) {
    if (!str) {
        return str;
    }
    let arr = str.split('-');
    let pref = '';
    if (arr.length > 1) {
        str = arr[1];
        if (type === 2) {
            let a = arr[0] * 1;
            pref = '+' + a + '-';
        }
    }
    if (hSst) {
        return pref + hideSst(str, 4, 4);
    } else {
        return pref + str;
    }
}

// 格式化大小
const sizeUnit = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export function formatSize(size, pointLength = 2) {
    let unit;
    const units = ['B', 'K', 'M', 'G', 'TB'];
    while ((unit = units.shift()) && size > 1024) {
        size = size / 1024;
    }
    return (unit === 'B' ? size : size.toFixed(pointLength === undefined ? 2 : pointLength)) + unit;
}

export function formatMoney(val, fcode) {
    return VF.formatMoney(val, {
        fcode
    }, null)
}

export function formatDate(val, format) {
    return VF.formatDate(val, format);
}
