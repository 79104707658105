<template>
    <div class="page">
        <div class="page-num">
            <ul class="page-lists">
                <li class="page-item page-prev" @click="gotoPage(pageNum - 1)">
                    <span class="arrow"></span>
                </li>
                <li class="page-item"
                    v-for="(page, idx) in pageArray"
                    :class="page === 0 ? 'ellipsis': (page === pageNum ? 'cur': '')"
                    @click="gotoPage(page)"
                >
                    <span v-if="page > 0" >{{ page }}</span>
                    <span v-else>...</span>
                </li>
                <li class="page-item page-next" @click="gotoPage(pageNum + 1)">
                    <span class="arrow"></span>
                </li>
            </ul>
        </div>
        <div class="page-other">
            <div class="page-goto">
                <p>{{ $t('pagination.total') }}<span>{{ totalPage }}</span>{{ $t('pagination.page') }} {{$t('pagination.goto')}}</p>
                <div class="page-input"><input type="num" placeholder="" v-model="targetNum"/></div>
                <p>{{ $t('pagination.page') }}</p>
            </div>
            <div class="page-sure" @click="jumpTo">{{ $t('confirm') }}</div>
        </div>
    </div>
</template>

<script>
import {arrayContains, obj2int} from "@/common/utils";
import Mixins from "@/common/core/mixins";

export default {
    name: "hc-page",
    mixins: [Mixins],
    props: {
        pageNum: {
            type: Number,
            default() {
                return 1;
            }
        },
        pageSize: {
            type: Number,
            default() {
                return 8;
            }
        },
        totalSize: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    computed: {
        totalPage() {
            return Math.floor(((this.totalSize - 1) / this.pageSize) + 1);
        },
        pageArray() {
            const array = [1]; // 增加第一页
            // 以当前页码为中心，前后各取三个，如果第三个后还有，变成...
            array.push(this.pageNum - 3);
            array.push(this.pageNum - 2);
            array.push(this.pageNum - 1);
            array.push(this.pageNum);
            array.push(this.pageNum + 1);
            array.push(this.pageNum + 2);
            array.push(this.pageNum + 3);
            array.push(this.totalPage);
            let res = [];
            for (let i = 0; i < array.length; i++) {
                const page = array[i];
                if (arrayContains(res, page)
                    || page < 1 || page > this.totalPage) {
                    continue;
                }
                res.push(page);
            }
            // 增加...
            if (res.length > 2) {
                if (res.length > 1 && res[1] > 2) {
                    res.splice(1, 0, 0);
                }
                const last = res[res.length - 2];
                if (last < (this.totalPage - 1)) {
                    res.splice(res.length - 1, 0, 0);
                }
            }
            return res;
        }
    },
    data() {
        return {
            targetNum: null
        }
    },
    methods: {
        jumpTo() {
            if (obj2int(this.targetNum)) {
                this.gotoPage(this.targetNum);
            }
        },
        gotoPage(pageNum) {
            if (pageNum > this.totalPage) {
                return;
            }
            if (pageNum && pageNum > 0) {
                this.$emit('gotoPage', pageNum);
            }
        }
    }
}
</script>