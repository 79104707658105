import request from "@/common/core/request";

export function addToCart(id, type, num = 1) {
    return request.post('/mall/cart/add', {
        id, type, num
    })
}

export function cartList() {
    return request.get('/mall/cart/list', {});
}

export function changeCartNum(hsscicode, qtc) {
    return request.post('/mall/cart/changeNum', {
        hsscicode, qtc
    })
}

export function deleteCart(cartIds) {
    return request.post('/mall/cart/delete', {cartIds});
}

export function removeInvalid() {
    return request.post('/mall/cart/removeInvalid', {});
}

/**
 *
 * @param cartIds 需要更新的购物车ID，不传则更新所有
 * @param checked 勾选或取消勾选
 * @returns {Promise | Promise<unknown>}
 */
export function checkCart(cartIds, checked) {
    return request.post('/mall/cart/checkCart', {cartIds, checked});
}


export function cartCount() {
    return request.get('/mall/cart/cartCount', {});
}