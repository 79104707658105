<template>
    <div>
        <button class="new-address" @click="createAddress" v-if="!inputShow">{{ $t('address.useNew') }}</button>
        <button class="new-address" @click="submitAddress" v-else>{{ $t('save') }}</button>
        <div class="new-address-content" v-show="inputShow">
            <div class="new-address-form">
                <div class="form-groups">
                    <div class="form-item">
                        <input type="text" :placeholder="$t('address.companyName')" v-model="form.companyname"/>
                    </div>
                </div>
                <div class="form-groups">
                    <div class="form-item">
                        <input type="text" :placeholder="$t('address.streetname')" v-model="form.streetname"/>
                    </div>
                    <div class="form-item min-form-item">
                        <input type="text" :placeholder="$t('address.housenumber')" v-model="form.housenumber"/>
                    </div>
                </div>
                <div class="form-groups">
                    <div class="form-item min-form-item">
                        <input type="text" :placeholder="$t('address.zipcode')" v-model="form.zipcode"/>
                    </div>
                    <div class="form-item">
                        <input type="text" :placeholder="$t('address.city')" v-model="form.city"/>
                    </div>
                </div>
                <div class="form-groups">
                    <div class="form-item">
                        <input type="text" :placeholder="$t('address.country')" v-model="form.country"/>
                        <div class="dropdown-icon">
                            <img src="@/assets/icon/arrow.png"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="remark">
                <label class="custom-checkbox">
                    <input type="checkbox" class="checkbox-input" v-model="form.dftaddr"/>
                    <span class="checkmark"></span>
                </label>
                <span>{{ $t('address.setAsDefault') }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import {addressInput} from "@/api/hc/address";

export default {
    name: "address-input",
    data() {
        return {
            inputShow: false,
            submitting: false,
            form: {
                hsadicode: null,
                status: null,
                companyname: null,
                phone: null,
                mobile: null,
                email: null,
                zipcode: null,
                streetname: null,
                city: null,
                country: null,
                housenumber: null,
                dftaddr: null,
                lastuse: null
            },
            list: []
        }
    },
    created() {
        if (process.env.NODE_ENV === 'development') {
            this.form = {
                hsadicode: null,
                status: null,
                companyname: '北京南北天科技股份有限公司杭州分公司',
                phone: null,
                mobile: null,
                email: null,
                zipcode: '310000',
                streetname: '文三路伟星大厦',
                city: '浙江省杭州市',
                country: '中国',
                housenumber: '19A',
                dftaddr: null,
                lastuse: null
            }
        }
    },
    methods: {
        createAddress() {
            this.showInput();
        },
        submitAddress() {
            const data = this.form;
            if (!this.checkData(data)) {
                return;
            }
            addressInput(this.form)
                .then(() => {
                    this.clearInput();
                    this.$emit('addressUpdated')
                });
        },
        showInput(address) {
            if (address) {
                this.form = address;
            }
            this.inputShow = true
        },
        checkData(data) {
            return true;
        },
        clearInput() {
            this.form = {
                hsadicode: null,
                status: null,
                companyname: null,
                phone: null,
                mobile: null,
                email: null,
                zipcode: null,
                streetname: null,
                city: null,
                country: null,
                housenumber: null,
                dftaddr: null,
                lastuse: null
            }
            this.inputShow = false;
        }
    }
}
</script>

<style scoped lang="scss">

</style>