
const proxyConfig = require('/proxy_config')
let baseUrl, prtRootPath, enableNetLog = false;
if (process.env.NODE_ENV === 'development') { // H5开发环境走代理
    const ori = window.location.origin;
    baseUrl = `${ori}/${proxyConfig.REST_PATH}/dev-api/rest`;
    prtRootPath = `${proxyConfig.REST_ORI}/${proxyConfig.REST_PATH}`;
} else {
    const ori = window.location.origin;
    const pathname = window.location.pathname;
    const path = proxyConfig.REST_PATH;
    baseUrl = `${ori}/${path}/rest`;
    prtRootPath = `${ori}/${path}`;
}
const uploadFileUrl = `${baseUrl}/upload/file`;
let host = baseUrl.replace('/rest', '');
host = host.replace('/dev-api', '');

const config = {
    baseUrl,
    uploadFileUrl,
    host,
    prtRootPath,
    enableNetLog
};

export default config;
