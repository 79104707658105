<template>
    <div class="">
        <hc-header/>
        <!--  网站声明-->
        <div class="statement-content w1200" v-if="language==='zh_CN'">
            <h1>杭叉网站隐私声明</h1>
            <p class="hc-prove">
                本隐私声明解释杭叉如何收集和使用其网站和移动网站（统称为“网站”）访客的个人数据。您的个人数据由杭叉集团有限公司（统称为“杭叉”、“我们”）控制。</p>
            <div class="para">
                <h2>一、我们手机的资料</h2>
                <p>
                    如果您致电给我们、向我们发送电子邮件或在我们的网站上填写表格，我们将使用您提供的资料来响应您的请求，或者与您进行联系或为您提供帮助，包括您的姓名、电话、电子邮箱地址等信息。如果您通过我们的网站进行购买，我们可能会收集处理您的订单所需的必要资料，例如您的送货地址、订购的产品以及您的联系资料。</p>
            </div>
            <div class="para">
                <h2>二、Cookies</h2>
                <p>杭叉使用 cookie技术。 cookie 本质上是储存于您的电脑、平板电脑、移动电话或其他设备上的小数据文件。</p>
            </div>
            <div class="para">
                <h2>三、资料使用、分享和转移</h2>
                <p>我们使用收集到的资料为您提供更丰富、更具互动性的体验。具体来说，我们可以使用资料以：</p>
                <ul class="p-list p-dot">
                    <li class="p-item">在您需要时提供服务或执行交易;</li>
                    <li class="p-item">改善和开发我们的产品;</li>
                    <li class="p-item">个性化您的体验;</li>
                    <li class="p-item">
                        在遵守适用法律要求的情况下向您推送广告和营销，其中可能包括发送促销信息、定向广告以及向您提供相关优惠。
                    </li>
                </ul>
                <p>我们不会出售您的个人资料。我们可能在下列情况披露您的个人资料：</p>
                <ul class="p-list">
                    <li class="p-item">1、遵守相关法律、法规要求以及回应法律要求、法庭命令以及法律程序；</li>
                    <li class="p-item">2、保护和捍卫我方或第三方的权利或财产权，包括执行协议、政策和使用条款；</li>
                    <li class="p-item">3、紧急情况下，包括保护我们雇员或任何人员的安全；</li>
                    <li class="p-item">4、与调查或预防诈骗有关的事宜。</li>
                </ul>
                <p>
                    我们可能会因涉及销售或转让我们全部或部分业务或资产的商务交易用途，或协商商务用途而分享您的个人资料。这些交易可能包括任何合并、财务、收购、资产剥离、合资或合伙经营、或者破产交易或诉讼。</p>
                <p>我们也会因性能与分析用途，自由使用与分享无法识别您个人身份的汇总和匿名资料。</p>
                <b>如果您从中国大陆以外地区访问我们网站，请您注意，您的信息可能被传送至、存储于中国，并且在中国进行处理。中国的数据保护法和其他法律可能与您所在国家/地区的法律不同，但请相信我们将采取适当措施保护您的信息。选择使用我们的服务和通过网站提交个人信息的同时，您了解并同意您的信息可能被传送至中国。</b>
            </div>
            <div class="para">
                <h2>四、儿童</h2>
                <p>我们不会蓄意收集或维护 13 岁以下的使用者的个人资料。如果我们得知在网站上或透过网站收集了有关 13
                    岁以下的使用者的资料，我们将采取适当的措施删除此类资料。如果您是已在我们的网站上注册帐户或服务的 13
                    岁以下儿童的家长或法定监护人，请使用以下联系资料与我们联系，以终止该儿童的帐户或服务，并删除个人资料。</p>
            </div>
            <div class="para">
                <h2>五、资料保留</h2>
                <p>我们会保留个人资料，直到提供功能和履行您所请求之交易，或用于其他合法目的，例如遵守我们的法律义务、解决争端和执行我们的协议直至完成为止。
                    由于资料类型的不同、我们与您的互动情况、或您对我们网站的使用情况会有所不同，因此实际的保留期间可能会有很大差异。</p>
            </div>
            <div class="para">
                <h2>六、资料安全</h2>
                <p>
                    我们使用加密、资料访问限制、防病毒软件和防火墙来减少未经授权访问个人资料的风险。在互联网或在任何无线网络上进行资料传输，都不会是绝对安全的。当您向我们传输个人资料以及在我们的公共论坛中发布资料时，请注意安全隐患。</p>
            </div>
            <div class="para">
                <h2>七、控制和访问资料</h2>
                <p>您可以要求我们更新或删除/停用与您有关的某些资料</p>
                <p>如果我们不能移除某些个人资料，我们会告知您原因。
                    如果您已订阅营销电子邮件，可以按照所接受电子邮件中的取消订阅说明，取消营销电子邮件发送。</p>
                <p>您也有权要求了解我们如何处理您的资料以及我们与谁分享资料。</p>
                <p>此外，如果您认为杭叉所处理之关于您的特定资料不正确，您有权更正此资料。
                    在收到此类请求后的 30 天内，我们将尽力答复您的请求，审查我们档案中存档的该资料。</p>
                <p>
                    如果您有关于您的资料或对我们的声明、之前的声明，以及资料业务有问题、意见或想要提出要求，请透过以下方式与我们联系：</p>
                <ol class="contact">
                    <li class="contact-item">电话：0571-88926755</li>
                    <li class="contact-item">电子邮件：sales@hcforklift.com</li>
                    <li class="contact-item">通信地址：浙江省杭州市临安区青山湖阶段相府路666号</li>
                </ol>
                <p>
                    如果您对我们的回复不满意，可将您的隐私权投诉或争议提交至您所在司法辖区的相应省或国家个人隐私保护机构。</p>
            </div>
            <div class="para">
                <h2>八、生效日期</h2>
                <p>本隐私声明自 2024年 4月 17日起生效。</p>
            </div>
        </div>
        <div class="statement-content w1200" v-else>
            <h1>Hangcha WEBSITE PRIVACY STATEMENT</h1>
            <p class="update"><b>Last updated:</b>January 2024</p>
            <p class="hc-prove">This privacy notice explains how Hangcha collects and uses personal data from visitors
                to its web and mobile sites that link directly to this notice (collectively “Sites”). Your personal data
                is controlled by Hangcha Group Co.,Ltd (collectively “Hangcha”, ''We” and “Us”).</p>

            <div class="para">
                <h2>1. DATA WE COLLECT</h2>
                <p>If you call us, send us an email, or fill out a form on one of our Sites, we use the information you
                    provide to respond to your request or to otherwise communicate with you or assist you, which may
                    include your name, phone number or email address. If you make purchases through our Sites, we may
                    collect data necessary to process your order, such as your shipping address, products ordered, and
                    your contact information.</p>
            </div>
            <div class="para">
                <h2>2. COOKIES</h2>
                <p>Hangcha uses cookies that are essentially small data files placed on your computer, tablet, mobile
                    phone, or other devices.</p>
            </div>
            <div class="para">
                <h2>3. DATA USE，SHARING and transfer</h2>
                <p>We use the data we collect to provide you with rich, interactive experiences. In particular, we may
                    use data to:</p>
                <ul class="p-list p-dot">
                    <li class="p-item">provide the service or carry out the transactions at your request;</li>
                    <li class="p-item">improve and develop our products;</li>
                    <li class="p-item">personalize your experience;</li>
                    <li class="p-item">advertise and market to you subject to applicable legal requirements, which may
                        include sending promotional communications, targeting advertising, and presenting you with
                        relevant offers.
                    </li>
                </ul>
                <p>We may disclose your personal information：</p>
                <ul class="p-list">
                    <li class="p-item">a.to comply with relevant laws, regulatory requirements, and to respond to lawful
                        requests, including to meet national security requirements, court orders, and legal process;
                    </li>
                    <li class="p-item">b.to protect and defend the rights or property of us or third parties, including
                        enforcing agreements, policies, and terms of use;
                    </li>
                    <li class="p-item">c.in an emergency, including to protect the safety of our employees or any
                        person;
                    </li>
                    <li class="p-item">d.or in connection with investigating or preventing fraud.</li>
                </ul>
                <p>We may share your personal data for purposes of a business deal, or negotiation of a business deal,
                    involving sale or transfer of all or a part of our business or assets. These deals can include any
                    merger, financing, acquisition, divestiture, joint venture or partnership, or bankruptcy transaction
                    or proceeding.</p>
                <p>We also freely use and share aggregated and pseudonymous information for performance and analytics
                    purposes that cannot be used to identify you individually.</p>
                <p>Please be aware of that if you visit our Sites from places outside the mainland of China, your data
                    may be transferred to China and stored and processed there. China’s law which protect personal
                    information may be different from the law in your country or region, but please believe in us that
                    we will take appropriate action to protect the security of your personal information.</p>
                <b>If you choose to use the service and submit your personal information through the Sites, you
                    acknowledge and agree that your data will be transferred to China.</b>
            </div>
            <div class="para">
                <h2>4. CHILDREN</h2>
                <p>We do not knowingly collect or maintain information about persons under 13 years of age. If we learn
                    that information about persons under 13 years of age has been collected on or through the Sites, we
                    will take appropriate steps to delete this information. If you are the parent or legal guardian of a
                    child under 13 years of age who has registered for an account or service on our Sites, please
                    contact us using the contact information below to have that child’s account or service terminated
                    and personal information deleted.</p>
            </div>
            <div class="para">
                <h2>5. DATA RETENTION</h2>
                <p>We retain personal data for as long as necessary to provide functionality and fulfill the
                    transactions you have requested, or for other legitimate purposes such as complying with our legal
                    obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for
                    different data types, the context of our interactions with you or your use of our Sites, actual
                    retention periods can vary significantly.</p>
            </div>
            <div class="para">
                <h2>6. DATA SECURITY</h2>
                <p>We use encryption, data access limitations, anti-virus software, and firewalls to reduce the risk of
                    unauthorized access to personal data. No data transmission over the Internet or any wireless network
                    is entirely secure. Be mindful of security risks when you transmit personal data to us and when you
                    post information in our public forums.</p>
            </div>
            <div class="para">
                <h2>7. CONTROLLING AND ACCESSING YOUR INFORMATION</h2>
                <p>You may request that we update or delete/deactivate certain information pertaining to you.</p>
                <p>If we cannot remove some personal information, we will let you know why. If you have subscribed to
                    marketing emails, you may stop the delivery by following the unsubscribe instructions contained in
                    the email received .</p>
                <p>You also have the right to request how your data is processed and with whom we share it.</p>
                <p>Additionally, if you believe certain information Hangcha processes about you is inaccurate, you have
                    the right to have this information corrected.</p>
                <p>We will endeavor to respond to your request to review the information we have on file for you within
                    30 days from receiving such request.</p>
                <p>If you have questions, comments, or would like to make a request regarding your data or our
                    Statement, past Statements, and data practices, please contact us through the following methods:</p>
                <ol class="contact">
                    <li class="contact-item">Phone:0571-88926755</li>
                    <li class="contact-item">Email:sales@hcforklift.com</li>
                    <li class="contact-item">Postal Mail:666 Xiangfu Road, Hangzhou, Zhejiang, China(311305) </li>
                </ol>
                <p>If you are not satisfied with our response, you can refer your privacy complaint or dispute to the
                    relevant provincial or national data protection authority in your jurisdiction.</p>
            </div>
        </div>
        <hc-footer/>
    </div>

</template>
<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import Mixins from "@/common/core/mixins";

export default {
    name: "",
    mixins: [Mixins],
    components: {HcFooter, HcHeader}
}
</script>
<style scoped lang="scss">

</style>