import request from "@/common/core/request";
import global_config from "@/common/core/global_config";
import Base64 from "@/common/utils/Base64";

const baseUrl = global_config.baseUrl;

export function checkLoginRequest() {
    return new Promise((resolve) => {
        const url = `${baseUrl.replace('/rest', '')}/uiinvoke/st-snsoft.hzcomm.comm.login.HzCommGetLoginInfo.checkIsLogin`;
        request.post(url, [{}], {silent: true, checkResCode: false})
            .then((res) => {
                resolve(res);
            })
            .catch(() => {
                resolve(false)
            })
    })
}

export function oauth2(code) {
    return request.post('/chart/oauth2/wx', {code});
}

export async function login({username, password, checkcode, language = 'zh_CN'}) {
    const {IDWORKSPC, SYSID} = await getLoginPageInfo();
    return await sendLoginRequest({
        username, password, checkcode, IDWORKSPC, SYSID, language
    });
}

export function getLoginPageInfo() {
    return new Promise(resolve => {
        const url = `${baseUrl.replace('/rest', '')}/uiinvoke/st-snsoft.hzcomm.comm.login.HzCommGetLoginInfo.getLoginPageInfo`;
        request.post(url, [{}], {silent: true, checkResCode: false})
            .then((res) => {
                if (typeof res === 'string') {
                    eval('res = ' + res);
                }
                const {sysOptions, wspOptions} = res;
                let SYSID = sysOptions ? sysOptions.selectedValue : null;
                let IDWORKSPC = wspOptions ? wspOptions.selectedValue : null;
                resolve({
                    IDWORKSPC, SYSID
                });
            });
    })
}

function sendLoginRequest({username, password, checkcode, IDWORKSPC, SYSID, language}) {
    return new Promise((resolve, reject) => {
        // const url = `${baseUrl.replace('/rest', '')}/uiinvoke/st-snsoft.hzcomm.comm.login.HzCommGetLoginInfo.getLoginInfo`;
        const url = `${baseUrl}/mbauth/login`;
        let a = Base64.toUTF8Bytes(password)
        for (let i = 0; i < a.length; i++) {
            a[i] ^= 0x13;
        }
        const base64x = "`" + Base64.encode(a) + "`";
        const loginData = {
            "USERCODENAME": username,
            "AUTHCODE": checkcode,
            "BASE64X.PWD": base64x,
            "__SERVLETENV": true,
            "CookiePath": "/caftp/",
            "IDWORKSPC": IDWORKSPC,
            "SYSID": SYSID,
            "LANGUAGE": language
        };
        request.post(url, loginData, {silent: true})
            .then((res) => {
                if (res) {
                    resolve(res)
                } else {
                    reject({
                        msg: '用户名或密码错误'
                    })
                }
            })
            .catch(({res}) => reject(res));
    });
}

export function getUserInfo() {
    return request.get('/mbauth/userInfo')
}

export function logout() {
    return new Promise((resolve, reject) => {
        request.post('/mbauth/logout')
            .then((res) => {
                uni.removeStorageSync('isLogin')
                resolve(res)
            })
            .catch((e) => {
                reject(e)
            })
    })
}