<template>

    <div class="step-content-item">
        <!-- 运输方式 -->
        <div class="type">
            <h4 class="step-content-title">{{ $t('order.transType.name') }}</h4>
            <ul class="transport">
                <li class="transport-item"
                    v-for="(item, idx) in list"
                    :key="idx"
                    @click="pickThis(item)"
                >
                    <input type="radio" class="radio-input" name="transtype" :checked="item.code===code"/>
                    <label class="transport-name">{{ $t(item.name) }}</label>
                </li>
            </ul>
        </div>
        <!--  币种    -->
<!--        <div class="type">
            <h4 class="step-content-title">{{ $t('order.payFcode') }}</h4>
            <ul class="transport">
                <li class="transport-item"
                    v-for="(item, idx) in fcodeList"
                    :key="idx"
                    @click="pickCoin(item)"
                >
                    <input type="radio" class="radio-input" name="cointype" :checked="item===fcode"/>
                    <label class="transport-name">{{ item }}</label>
                </li>
            </ul>
        </div>-->
    </div>
</template>
<script>
export default {
    name: "trans-type",
    data() {
        return {
            code: null,
            list: [
                {
                    "name": 'order.transType.sea',
                    "code": "10"
                },
                {
                    "name": 'order.transType.air',
                    "code": "20"
                },
                {
                    "name": 'order.transType.dhl',
                    "code": "30"
                },
                {
                    "name": 'order.transType.fedex',
                    "code": "40"
                },
                {
                    "name": 'order.transType.forklifts',
                    "code": "50"
                }
            ],
            fcode: null
        }
    },
    props: {
        fcodeList: {
            type: Array
        }
    },
    methods: {
        pickThis({code}) {
            this.code = code;
        },
        pickCoin(fcode) {
            this.fcode = fcode;
        },
        getFreighttype() {
            return this.code;
        },
        getFcode() {
            return this.fcode;
        }
    }
}
</script>

<style scoped lang="scss">
.step-content-item {
    padding-bottom: 20px;
}
</style>