import {cartCount} from "@/api/hc/cart";
import store from "@/common/store";
import {loadMessageCount} from "@/api/hc";

const Count = {
    setCartCount: 'SET_COUNT_CART',
    setMessageCount: 'SET_MESSAGE_COUNT'
}

const state = {
    cart: null,
    message: null
}

const mutations = {
    [Count.setCartCount]: (state, num) => {
        state.cart = num;
    },
    [Count.setMessageCount]: (state, num) => {
        state.message = num;
    }
}

const actions = {
    async getCartCount({state, commit}) {
        const isLogin = store.getters.isLogin;
        if (isLogin) {
            cartCount().then(({num}) => {
                commit(Count.setCartCount, num);
            }).catch(e => {
            })
        }
    },
    async getMessageCount({state, commit}, {wsid, lang}) {
        const isLogin = store.getters.isLogin;
        if (isLogin) {
            loadMessageCount(wsid, lang)
                .then(num => {
                    commit(Count.setMessageCount, num);
                })
        }
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
