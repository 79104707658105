import ValueTypes from "@/common/ui/util/ValueTypes";
import {deepClone, isBlank, isNotBlank, replaceMacro} from "@/common/utils";
import {formatDate} from "@/common/utils/date-format";
import store from "@/common/store";
import DataSetRow from "@/common/ui/dx/dataSetRow";

const fcodeSymbol = {
    'USD': '$',
    'CNY': '￥',
    'RMB': '￥'
}

function ValueFormatter() {

}

ValueFormatter.prototype.format = function (value, column, rowData) {
    if (column == null) {
        console.warn('column is null');
        return value;
    }
    const type = column.type;
    let res = value;
    switch (type) {
        case ValueTypes.TEXT:
        case ValueTypes.TEXTAREA:
            // 文本内容，如果有配置宏，使用宏替换
            if (column.macro) {
                res = replaceMacro(column.macro, rowData);
            }
            break;
        case ValueTypes.DATE: // 日期格式
            res = this.formatDate(value, column.format);
            break;
        case ValueTypes.CODETBL:
            // 这里不处理码表的值，如果需要转码名，获取数据后自行处理
            break;
        case ValueTypes.NUMERIC:
            if (column.percent) {
                res = this.formatPercent(value, column.percentMultiplier);
            } else if (column.money) { // 金额，先格式化为数字，再格式化为金额
                res = this.formatMoney(value, column, rowData);
            } else { // 普通的数字
                res = this.formatNumeric(value, column.maxdeci, column.mindeci);
            }
            break;
        case ValueTypes.INTEGER: // 整数，不处理
            break;
        case 'date':
            break;
    }
    return res;
}

ValueFormatter.prototype.formatNumeric = function (value, maxdeci = 2, mindeci= 0) {
    let res = value;
    // 保留最大小数位
    const num = parseFloat(value);
    if (isNaN(num)) {
        return value;
    }
    res = Math.round(num * Math.pow(10, maxdeci)) / Math.pow(10, maxdeci);
    res = (res || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    // 保留最小小数位，不足的补0
    if (mindeci != null) {
        let s = res.toString();
        let pos = s.indexOf('.');
        if (pos < 0) {
            s += '.';
            pos = s.length - 1;
        }
        while (s.length <= pos + mindeci) {
            s += '0';
        }
        res = s;
    }
    return res;
}

ValueFormatter.prototype.formatMoney = function (value, {
    maxdeci = 2, mindeci = 2, format, fcode, fcodeKey, moneyPrefix = 'symbol'
}, rowData) {
    let number = Number(value);
    let prefix = '';
    if (moneyPrefix !== 'none') {
        if (isBlank(fcode) && fcodeKey && rowData) {
            fcode = rowData[fcodeKey];
        }
        if (moneyPrefix === 'code') {
            prefix = fcode + ' ';
        } else if (moneyPrefix === 'symbol') {
            prefix = this.getFcodeSymbol(fcode);
        }
    }

    return prefix + this.formatNumeric(number, maxdeci, mindeci);
}

ValueFormatter.prototype.formatPercent = function (value, multiplier = true) {
    const m = multiplier ? 100 : 1
    return `${value * m}%`;
}

ValueFormatter.prototype.formatCodetbl = async function (value, codetbl, cmparams) {
    const config = {
        sql: codetbl,
        filter: false
    }
    if (cmparams) {
        config.cmparams = cmparams;
    }
    const codeName = await store.dispatch('codeData/getCodeDataName', {
        config, code: value
    });
    return isNotBlank(codeName) ? codeName : value;
}

ValueFormatter.prototype.formatDate = function (value, format) {
    return formatDate(value, format);
}

ValueFormatter.prototype.dealCodeName = async function (rowData, columns) {
    for (let propkey in columns) {
        const col = columns[propkey];
        if (col.type === ValueTypes.CODETBL) {
            const {codetbl} = col;
            rowData[propkey] = await this.formatCodetbl(rowData[propkey], codetbl, col.cmparams);
        }
    }
}

/**
 * 处理日期格式
 * @param rowData 数据集
 * @param columns 列配置
 * @param returnNull 控制是否返回null（显示用不返回null，返回''，避免空值显示null）
 */
ValueFormatter.prototype.dealDateTime = function (rowData, columns, returnNull = false) {
    for (let propkey in columns) {
        const col = columns[propkey];
        if (col.type === ValueTypes.DATE) {
            if (isBlank(rowData[propkey]) && returnNull) {
                rowData[propkey] = null;
            } else {
                rowData[propkey] = formatDate(rowData[propkey], col.format);
            }
        }
    }
}

/**
 * 格式化数据列表，一般列表展示使用
 */
ValueFormatter.prototype.formDataList = async function (list, columns, dealCodeName = true) {
    for (let i in list) {
        const item = list[i];
        VF.dealDateTime(item, columns);
        if (dealCodeName) {
            await VF.dealCodeName(item, columns);
        }
    }
    for (let i in list) {
        const item = list[i];
        for (let columnsKey in columns) {
            item[columnsKey] = VF.format(item[columnsKey], columns[columnsKey], item);
        }
    }
}

/**
 * 格式化 dataSetRow数据集，将结果放入 row.showForm，一般用于子表卡片展示
 * @param rows
 * @param columns
 * @param dealCodeName
 * @returns {Promise<void>}
 */
ValueFormatter.prototype.formatDataSetRows = async function (rows, columns, dealCodeName = true) {
    for (let rowsKey in rows) {
        const row = rows[rowsKey];
        await VF.formatRow(row, columns, dealCodeName);
    }
}

ValueFormatter.prototype.formatRow = async function (data, columns, dealCodeName = true) {
    let res = data;
    if (data instanceof DataSetRow) {
        res = deepClone(data.form);
    }
    VF.dealDateTime(res, columns);
    if (dealCodeName) {
        await VF.dealCodeName(res, columns);
    }
    for (let propkey in columns) {
        const col = columns[propkey];
        res[propkey] = VF.format(res[propkey], col, res);
    }
    if (data instanceof DataSetRow) {
        data.showForm = res;
    }
}

ValueFormatter.prototype.getFcodeSymbol = function (fcode) {
    if (isBlank(fcode)) {
        return '';
    }
    return fcodeSymbol[fcode.toUpperCase()] || '';
}

const VF = new ValueFormatter();
export default VF;