export default {
    companyName: '杭叉集团股份有限公司',
    slogan: '专业化生产、品牌化经营、集团化运作、国际化整合',
    homepage: '首页',
    messageCenter: '消息中心',
    save: '保存',
    edit: '编辑',
    delete: '删除',
    confirm: '确定',
    noAuth: '用户未登录',
    date: '日期',
    remark: '备注',
    request: {
        failed: '请求失败'
    },
    index: {
        recommend: '特别推荐',
        slogan: '杭叉集团 研发制造',
        adv: '专业化生产、品质话经营、集团化运作、国际化整合',
        chinaMI: '中国制造业',
        devHistory: '发展历史',
        holdingSubsidiary: '控股子公司',
        overseasDistributor: '海外经销商',
        group: '集团',
        announcement: '公告',
        more: '查看更多',
        contactPhone: '全国服务热线',
        top: '强',
        pcs: '个',
        copyright: 'Copyright©2019 All Reserved 版权所有：杭叉集团股份有限公司',
        desc1: '杭叉集团是中国目前最大的叉车研发制造集团之一。目前集团公司下属90家控股子公司、10家参股公司，集团共有员工6000余人。母公司杭叉集团股份有限公司是由原杭州叉车总厂经两次改制成立的股份有限公司，2016年12月在上海证券交易所主板（A股）成功上市，股票代码603298，注册资本86639.5852万元。',
        desc2: '杭叉集团产品销量十多年持续位列行业前茅，连续十年行业出口名列前茅。据美国《MMH现代物料搬运杂志》对全球叉车按销售额排名，杭叉集团位列世界第8位。',
        searchParts: '按零件查找',
        search: '搜索',
        searchPlaceHolder: '请输入零件号或名称'
    },
    cart: {
        name: '购物车',
        add: '加入购物车',
        addSuccess: '添加成功！',
        checkAll: '全选',
        goodsInfo: '商品信息',
        unitPrice: '单价',
        num: '数量',
        operate: '操作',
        replaceTime: '预计补货时间',
        delete: '删除',
        invalidLength: '件失效商品',
        clear: '清空',
        invalid: '已失效',
        clearInvalid: '清除失效产品',
        total: '合计',
        order: '下单',
        chosenToDelete: '请选择要删除的商品！',
        confirmToDelete: '您确定要删除所选商品吗？',
        placeChosenToBy: '请勾选要购买的商品！',
        productNotExist: '商品不存在或已下架',
        cartGoodsNotExist: '购物车商品不存在！',
        chosenHasInvalid: '所选商品中有失效商品，请重新选择！',
        importByExcel: '从Excel导入',
        totalImportError: '共{0}条数据导入失败！',
        showAll: '显示全部',
        showOnlyFailed: '只显示失败数据',
        no: '序号',
        errorMessage: '错误信息',
        downloadTemplate: '下载模板',
        dropFileHere: '将文件拖到此处，或',
        click2upload: '点击上传',
        uploadTip: '只能上传xls文件',
        materialCode: '物料编码'
    },
    product: {
        center: '产品中心',
        search: '搜索产品',
        code: '物料编号',
        goodsInfo: '商品信息',
        figurenum: '图号',
        stock: '库存',
        title: '产品系列展示',
        advantage: '一流的研发实力/一流的生产设备/一流的试验检测设备/一流的质量控制水平',
        estarridate: '预计交货期',
        day: '天',
        login2view: '请登录查看报价',
    },
    partCatalogues: {
        name: "零件目录"
    },
    login: {
        login: '登录',
        placeholder: {
            username: '请输入用户ID/手机号/邮箱',
            password: '请输入密码',
            checkcode: '请输入验证码'
        },
        nousername: '无效的用户名或密码错误',
        logout: '登出'
    },
    address: {
        title: '常用地址',
        companyName: '公司名称',
        streetname: '街道名称',
        housenumber: '门牌号',
        zipcode: '邮编',
        city: '城市',
        country: '国家/地区',
        setAsDefault: '设置为默认地址',
        setDefault: '设置默认',
        useNew: '使用新地址',
        notExist: '地址不存在或已删除！'
    },
    order: {
        step: {
            address: '填写地址信息',
            order: '确定订单信息',
            submit: '提交订单'
        },
        nextStep: '下一步',
        preStep: '上一步',
        confirmOrderInfo: '确认订单信息',
        goodsAmount: '总商品金额',
        actualPay: '实付款',
        totalAmount: '总金额',
        deliveryTo: '寄送至',
        submitSuccess: '恭喜您！订单提交成功！',
        viewOrder: '查看订单',
        transType: {
            name: '运输方式',
            sea: '海运',
            air: '空运',
            express: '快递',
            dhl: 'DHL',
            fedex: '联邦快递',
            forklifts: '随整机'
        },
        payFcode: '结算货币',
        noChosenGoods: '未加载到选中商品！',
        palceholder: {
            fcode: '请选择结算币种！',
            transType: '请选择运输方式！',
            address: '请选择收货地址！'
        },
        noFreight: '当前订单不含运费！'
    },
    pagination: {
        total: '共',
        page: '页',
        goto: '去第'
    },
    catalogue: {
        slogan: '在这里你可以找到我们所有的文件',
        placeholder: '可输入车型数据/文件名',
        search: '搜索',
        login2search: '请登录后搜索'
    }
}