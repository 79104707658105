import { render, staticRenderFns } from "./hc-footer.vue?vue&type=template&id=853fa6e2&scoped=true"
import script from "./hc-footer.vue?vue&type=script&lang=js"
export * from "./hc-footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "853fa6e2",
  null
  
)

export default component.exports