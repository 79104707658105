<template>
    <div class="order">
        <div class="order-step">
            <div class="w1200">
                <ul class="step-wrap">
                    <li class="step-item cur">
                        <p class="step-num">1</p>
                        <p class="step-name">{{ $t('order.step.address') }}</p>
                    </li>
                    <li class="step-item">
                        <p class="step-num">2</p>
                        <p class="step-name">{{ $t('order.step.order') }}</p>
                    </li>
                    <li class="step-item">
                        <p class="step-num">3</p>
                        <p class="step-name">{{ $t('order.step.submit') }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 具体内容 -->
        <div class="step-content">
            <div class="w1200">
                <MailAddress ref="address"/>
                <TransType ref="transType" :fcode-list="fcodeList"/>
                <!-- 下一步 -->
                <div class="order-bar">
                    <button class="gorder" @click="gotoNext">{{ $t('order.nextStep') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import MailAddress from "@/pages/order/components/mail-address.vue";
import TransType from "@/pages/order/components/trans-type.vue";

export default {
    name: "OrderStep1",
    components: {TransType, MailAddress},
    data() {
        return {
            newPanelShow: false //新建新地址内容 默认
        }
    },
    props: {
        fcodeList: {
            type: Array
        }
    },
    methods: {
        gotoNext() {
            const address = this.$refs.address.getChosenAddress();
            const freight = this.$refs.transType.getFreighttype();
            const fcode = this.$refs.transType.getFcode();
            this.$emit('gotoNext', {address, freight, fcode});
        },
        getHsadicode() {
            return this.$refs.address.getHsadicode();
        },
        getFreighttype() {
            return this.$refs.transType.getFreighttype();
        },
        getFcode() {
            return this.$refs.transType.getFcode();
        }
    }
}

</script>
<style scoped>
page {
    background: #f1f1f1;
}
</style>