<template>
    <el-dialog :visible.sync="visible" :center="true">
        <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;">
            <el-button :style="buttonStyle" type="text" v-if="showAll" @click.native="showAll = false">{{ $t('cart.showOnlyFailed') }}</el-button>
            <el-button :style="buttonStyle" type="text" v-else @click.native="showAll = true">{{ $t('cart.showAll') }}</el-button>
            <span>{{$t('cart.totalImportError',[errorCount])}}</span>
        </div>
        <el-table
            :data="showAll ? tableData : errorTableData"
            height="300"
            border
            style="width: 100%"
            :row-class-name="tableRowClassName"
        >
            <el-table-column
                prop="no"
                :label="$t('cart.no')"
                width="80">
            </el-table-column>
<!--            <el-table-column
                prop="bjicode"
                :label="$t('cart.materialCode')"
                width="220">
            </el-table-column>-->
            <el-table-column
                prop="figurenum"
                :label="$t('product.figurenum')"
                width="220">
            </el-table-column>
            <el-table-column
                prop="num"
                :label="$t('cart.num')"
                width="80"
            >
            </el-table-column>
            <el-table-column
                prop="message"
                :label="$t('cart.errorMessage')">
            </el-table-column>
        </el-table>
    </el-dialog>
</template>
<script>
export default {
    name: "cart-import-res",
    data() {
        return {
            visible: false,
            tableData: [],
            errorTableData: [],
            errorCount: 0,
            showAll: true,
          buttonStyle:{
            background: '#065d92',
            color: '#fff',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingTop: '12px',
            paddingBottom: '12px',
            fontSize: '13px',
            marginRight: '10px'
          }
        }
    },
    methods: {
        show(xlsData) {
            this.tableData = [];
            this.tableData = xlsData;
            this.visible = true;
            let count = 0;
            const errorTableData = [];
            for (let i = 0; i < xlsData.length; i++) {
                const {success} = xlsData[i];
                if (!success) {
                    errorTableData.push(xlsData[i])
                    count++;
                }
            }
            this.errorTableData = errorTableData;
            this.errorCount = count;
        },
        tableRowClassName({row, rowIndex}) {
            const {success} = row;
            if (!success) {
                return 'error-row';
            }
        }
    }
}
</script>

<style lang="scss">
.el-table .el-table__header-wrapper th.el-table__cell{
  color: #333;
  text-align: center;
  background: #f1f1f1;
}
.el-dialog__headerbtn .el-dialog__close{
  font-size: 24px;
  font-weight: 700;
}
.el-dialog__headerbtn .el-dialog__close:hover{
  color: #065d92;
}
.el-table .el-table__header-wrapper .el-table__cell,.el-table .el-table__body-wrapper .el-table__cell{
  padding: 10px 0;
}
.el-table__row td:nth-child(2n+1) .cell{
  text-align: center;
}
.el-table .el-table__body-wrapper td.el-table__cell, .el-table .el-table__header-wrapper th.el-table__cell.is-leaf {
  border-bottom: 1px solid #dadfe6;
  border-right: 1px solid #dadfe6;
}
.el-table .el-table__body-wrapper td.el-table__cell:last-child{
  border-right: 0;
}
.el-table .el-table__header-wrapper th.el-table__cell.is-leaf:last-child{
  border-right: 0;
}
.el-dialog__body .el-table--border,.el-table .el-table--group {
  border: 1px solid #dadfe6;
}
.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close{
  color: #065d92;
}
.el-upload-dragger .el-upload__text em{
  color: #065d92;
}
.el-table .error-row {
    background: #f6e1e0;
}

.el-table .success-row {
    background: #f0f9eb;
}
::v-deep .el-button--text，::v-deep .el-button--text:hover{
  background: #065d92;
  color: #fff;
  padding: 8px 12px;
  font-size: 13px;
  margin-right: 10px;
}
</style>
