<template>
    <div>
        <div class="logon">
            <div class="w1200 logon-content">
                <!-- 登录文案 -->
<!--                <div class="slogan">-->
<!--                    <h1 class="s-en">H<em class="key">A</em>NGC<em class="key">H</em>A</h1>-->
<!--                    <h2 class="s-ch">{{ $t('index.slogan') }}</h2>-->
<!--                    <p class="s-desc">{{ $t('index.adv') }}</p>-->
<!--                    <button class="more-btn" onclick="window.location.href='index.html'">{{ $t('index.more') }}</button>-->
<!--                </div>-->
                <div class="login-pic">
                  <img src="@/assets/login_pic.png" />
                </div>
                <!-- 登录框 -->
                <hc-login/>
            </div>
        </div>
<!--        <hc-footer/>-->
    </div>
</template>
<script>
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import HcLogin from "@/components/busi/login/hc-login.vue";

export default {
    name: "login",
    components: {HcLogin, HcFooter}
}
</script>

<style scoped lang="scss">

</style>