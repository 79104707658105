const Base64 = {
    toBase64Code: function (x) {
        if (x < 0 || x >= 64)
            throw new Error();
        if (x <= 25)
            return 0x41 + x;
        if (x <= 51)
            return 0x61 + x - 26;
        if (x <= 61)
            return 0x30 + x - 52;
        if (x == 62)
            return 0x2b;
        if (x == 63)
            return 0x2f;
        return 0x3d;
    },
    encode: function (a) {
        if (a == null)
            return null;
        var remainder = 0, bitsRemainder = 0, outCount = 0, str = [];
        for (var i = 0; i < a.length; i++) {
            var c = a[i] & 0xff;
            for (; ;) {
                if (bitsRemainder >= 6) {
                    str.push(String.fromCharCode(Base64.toBase64Code(remainder >> bitsRemainder - 6 & 63)));
                    outCount++;
                    bitsRemainder -= 6;
                    continue;
                }
                if (c < 0)
                    break;
                remainder = remainder << 8 | c & 0xff;
                bitsRemainder += 8;
                c = -1;
            }
        }
        if (bitsRemainder > 0) {
            str.push(String.fromCharCode(Base64.toBase64Code(remainder << 6 - bitsRemainder & 63)));
            outCount++;
        }
        for (; (outCount & 3) != 0; outCount++)
            str.push("=");
        return str.join("");
    },
    decode: function (s) {
        var n = s.length, p = 0, bitsRemainder = 0, remainder = 0, a = new Uint8Array(s.length * 3), i = 0;
        for (; ;) {
            if (bitsRemainder >= 8) {
                var c = remainder >> bitsRemainder - 8;
                bitsRemainder -= 8;
                a[i++] = c;
                continue;
            }
            var indata;
            for (; ;) {
                indata = p >= n ? -1 : s.charCodeAt(p++);
                if (indata < 0 || indata > 0x20)
                    break;
            }
            if (indata < 0)
                break;
            if (indata == 0x3d)
                continue;
            remainder = remainder << 6 | Xjs.util.Base64.fromBase64Code(indata);
            bitsRemainder += 6;
        }
        return a.subarray(0, i);
    },
    readUTF8: function (a) {
        var n = a.length, str = [];
        for (var i = 0; i < n;) {
            var c = a[i++] & 0xff, c2, c3;
            switch (c >> 4) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    str.push(String.fromCharCode(c));
                    break;
                case 12:
                case 13:
                    c2 = a[i++] & 0xff;
                    if ((c2 & 0xc0) != 0x80)
                        throw new Error();
                    str.push(String.fromCharCode((c & 0x1f) << 6 | c2 & 0x3f));
                    break;
                case 14:
                    c2 = a[i++] & 0xff;
                    c3 = a[i++] & 0xff;
                    if ((c2 & 0xc0) != 0x80 || (c3 & 0xc0) != 0x80)
                        throw new Error();
                    str.push(String.fromCharCode((c & 0xf) << 12 | (c2 & 0x3f) << 6 | (c3 & 0x3f) << 0));
                    break;
                default:
                    throw new Error();
            }
        }
        return str.join("");
    },
    toUTF8Bytes: function (s) {
        if (window && window.TextEncoder)
            return (new TextEncoder("utf-8")).encode(s);
        var a = new Uint8Array(s.length * 3), n = 0;
        for (var i = 0; i < s.length; i++) {
            var c = s.charCodeAt(i);
            if (c < 0x80)
                a[n++] = c;
            else if (c < 0x800) {
                a[n++] = 0xc0 | c >> 6;
                a[n++] = 0x80 | c & 0x3f;
            } else {
                a[n++] = 0xe0 | c >> 12;
                a[n++] = 0x80 | c >> 6 & 0x3f;
                a[n++] = 0x80 | c & 0x3f;
            }
        }
        return a.subarray(0, n);
    },
    decodeStr: function (s) {
        return Base64.readUTF8(Base64.decode(s));
    }
};

export default Base64;
