import Vue from 'vue'
import App from './App.vue'
import router from "@/common/router";

Vue.config.productionTip = false

import i18n from "@/i18n";

import Uni from "@/common/utils/uni";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import localeEn from 'element-ui/lib/locale/lang/en';
import localeCN from 'element-ui/lib/locale/lang/zh-CN'

Vue.use(ElementUI, {locale: i18n.locale === 'en' ? localeEn : localeCN});

import store from "@/common/store";

Vue.prototype.$store = store;

window.uni = new Uni();

import dialog from './common/utils/dialog';

Vue.use(dialog);

import * as filters from '@/common/utils/filters'
import {isBlank} from "@/common/utils";
// register global utility filters
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

new Vue({
    render: h => h(App),
    router, i18n, store
}).$mount('#app');

store.dispatch('lang/setLanguage', i18n.locale);