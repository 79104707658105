import request from "@/common/core/request";
import global_config from "@/common/core/global_config";
const baseUrl = global_config.baseUrl;

export function loadIndexBulletin() {
    return request.post('/mall/index/bulletin', {})
}

export function loadMessageCount(wsid, language) {
    return request.post(`${baseUrl.replace('/rest', '')}/uiinvoke/${wsid}/${language}/st-snsoft.ftcs.warn.bas.WarnHomePage.loadMessageCount`, [{}],
        {checkResCode: false, silent: true});
}

export function getPubAttachment(keyword) {
    return request.post('/mall/index/pubAttachment', {keyword})
}

export function getMenuCfg() {
    return request.post('/mall/index/menuCfg', {})
}