<template>
    <div style="background: #ffffff">
        <!-- 头部 -->
        <div :class="{'index-header':isScroll}">
            <hc-header :hide-search="true"/>
        </div>
        <!-- 主体 -->
        <div class="content" style="padding-top: 0">
            <!-- banner -->
            <div class="banner">
                <div class="banner-box">
              <img src="@/assets/banner/banner01.jpg" class="imgBg" />
                    <div class="banner-content">
                        <h2 class="banner-title">{{ $t('index.searchParts') }}</h2>
                        <div class="search" @keyup.enter="keywordChange">
                            <div class="search-input-box">
                                <i class="iconfont search-icon">&#xe747;</i>
<!--                                <input class="search-input" :placeholder="$t('index.searchPlaceHolder')" type="text" v-model="keyword" />-->
                                <input class="search-input" type="text" v-model="keyword" />
                            </div>
                            <button class="search-btn" @click="keywordChange">{{ $t('index.search') }}</button>
                        </div>
                    </div>
                </div>

            </div>
            <product-recommend/>

        </div>
       <hc-footer />
        <hc-sidenav/>
    </div>
</template>
<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import ProductList from "@/components/busi/product/product-list.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import HcLogin from "@/components/busi/login/hc-login.vue";
import HcSidenav from "@/components/busi/sidenav/hc-sidenav.vue";
import Mixins from "@/common/core/mixins";
import {loadIndexBulletin} from "@/api/hc";
import ProductRecommend from "@/pages/homepage/components/product-recommend.vue";
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/dist/css/swiper.min.css'
import {isBlank, isNotBlank} from "@/common/utils";
import {getLoginPageInfo} from "@/api/core/auth"; // 注意这里的引入
export default {
    name: "HomePage",
    components: {ProductRecommend, HcSidenav, HcLogin, HcFooter, ProductList, HcHeader},
    mixins: [Mixins],
    created() {
        this.loadBulletin();
    },
    data() {
        return {
            bulletin: null,
            isScroll: true,
            keyword: null
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 60) {
                this.isScroll = false;
            } else {
                this.isScroll = true;
            }
        },
        loadBulletin() {
            loadIndexBulletin()
                .then(({bulletin}) => {
                    this.bulletin = bulletin;
                });
        },
        async gotoBulletin() {
            const href = this.getErpHref();
            const {bulletinicode} = this.bulletin;
            let wsid = this.env.idworkspc;
            const lang = this.$i18n.locale;
            if (isBlank(wsid)) {
                const res = await getLoginPageInfo();
                wsid = res.IDWORKSPC;
            }
            window.open(`${href}/uiinvoke/${wsid}/${lang}/theme1/5031.HC.502.12.30.html#?InitValue.bulletinicode=${bulletinicode}&AutoRefresh=1`)
        },
        keywordChange(e) {
            let v = this.keyword;
            if (isBlank(v)) {
                return;
            }
            if (isNotBlank(v)) {
                v = v.toUpperCase();
            }
            const path = this.$route.path;
            if ('/product' === path) {
                this.$emit('search', v);
            } else {
                this.$router.push({
                    path: '/product',
                    query: {
                        keyword: encodeURIComponent(v)
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
body {
    background: #fff;
}
</style>