import {login, checkLoginRequest} from "@/api/core/auth";
import Store from "@/common/store";

const getClientid = () => {
    return Store.getters.clientid;
}

const usernameLogin = (data) => {
    return new Promise((resolve, reject) => {
        const params = {
            ...data,
            clientid: getClientid()
        }
        login(params).then((res) => {
            resolve(res);
        }).catch(e => {
            reject(e);
        });
    });
}

/**
 * 统一登录接口
 * @param type username: 用户名密码登录， third: 第三方登录， auto：自动登录
 * @param data 登录数据， type=username 为用户名、密码，type=third 为 provider
 */
export async function authLogin(type = 'username', data) {
    let res = null;
    if (type === 'username') {
        res = await usernameLogin(data);
    }
    await Store.dispatch('user/setUserLogin');
    await Store.dispatch('count/getCartCount', res);
    return res;
}

export async function checkIsLogin() {
    const isLogin = await checkLoginRequest();
    return isLogin;
}
