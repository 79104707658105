import {Loading, Notification, MessageBox} from "element-ui";

function Uni() {

}

Uni.prototype.$toast = function (message, duration = 2500) {
    Notification({message, duration})
}

Uni.prototype.$alert = function (message, title = '') {
    return new Promise(resolve => {
        MessageBox.alert(message, title)
            .then(() => resolve())
            .catch(() => resolve())
    });
}

Uni.prototype.$confirm = function (message, title) {
    return new Promise((resolve, reject) => {
        MessageBox.confirm(message, title)
            .then(() => {
                resolve();
            })
            .catch(() => {
                reject();
            })
    })
}

Uni.prototype.$loading = Loading.service;

/**
 * 数据存储
 */
Uni.prototype.setStorageSync = function (key, value) {
    const v = typeof value === "object" ? JSON.stringify(value) : value;
    localStorage.setItem(key, v)
}

Uni.prototype.getStorageSync = function (key) {
    return localStorage.getItem(key);
}

Uni.prototype.removeStorageSync = function (key) {
    localStorage.removeItem(key);
}

export default Uni;