<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "@/common/style/base.scss";
@import "@/common/style/style.scss";
@import "@/common/style/swiper.min.css";
@import "@/common/style/iconfont.css";
</style>
