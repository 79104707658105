import request from "@/common/core/request";
import {isBlank} from "@/common/utils";

export function getProductList(keyword, pageNum, pageSize) {
    return request.post('/mall/product/list', {
        keyword: isBlank(keyword) ? null : keyword,
        pageNum, pageSize
    })
}

export function getRecommendProductList() {
    return request.post('/mall/product/recommend', {})
}


export function getProductDetail(id) {
    return request.post('/mall/product/detail', {
        id
    })
}