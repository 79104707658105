<template>
    <div class="sell">
        <h4>{{ $t('index.recommend') }}</h4>
        <div class="hot-product-box" id="recommend">
            <ul class="sell-product">
                <li class="sell-item"
                    v-for="(item, idx) in list"
                    :key="idx"
                    @click="gotoDetail(item.id)"
                >
                    <div class="product-img">
                        <img :src="item.image || defaultPng"/>
                    </div>
                    <div class="product-base">
                        <div class="product-left">
                            <h2 class="product-name" v-if="language==='en'">{{ item.name_en }}</h2>
                            <h2 class="product-name" v-else>{{ item.name_cn }}</h2>
                            <p class="pic-num">{{ item.figurenum }}</p>
                        </div>
                        <p class="product-cart" @click.stop="addCart(item)"><img src="@/assets/icon/cart.png" class="cartIcon"></p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import {getRecommendProductList} from "@/api/hc/product";
import {addToCart} from "@/api/hc/cart";
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/dist/css/swiper.min.css'
import Mixins from "@/common/core/mixins"; // 注意这里的引入

export default {
    name: "product-recommend",
    mixins: [Mixins],
    created() {
        this.loadData();
    },
    data() {
        return {
            list: []
        }
    },
    methods: {
        loadData() {
            getRecommendProductList()
                .then(({list}) => {
                    this.list = list;
                    // this.$nextTick(() => {
                    //     this.initSwiper();
                    // });
                })
                .catch(e => {
                })
        },
        initSwiper() {
            this.swiper = new Swiper('.swiper-container', {
                direction: 'horizontal', // 垂直切换选项
                loop: true, // 循环模式选项
                speed: 1500,
                autoplay: {
                    enable: true,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            })
        },
        addCart(product) {
            addToCart(product.id, product.type, 1)
                .then(() => {
                    this.$toast(this.$t('cart.addSuccess'));
                    // 刷新购物车数量
                    this.$store.dispatch('count/getCartCount');
                });
        },
        gotoDetail(id) {
            this.$router.push({
                path: '/item',
                query: {
                    id
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">

</style>