function DataSetRow(columns, idx) {
    // dataSet为单条数据
    this.form = {};
    this.idx = idx;
    this.columns = columns;
    this.initForm();
    this.cascade = null;
    // 格式化后的数据集，一般详情子表等处使用，需要用 VF 格式化处理后使用
    this.showForm = null;
}

DataSetRow.prototype.setValue = function (key, value) {
    if (typeof value === 'undefined') {
        value = null;
    }
    this.form[key] = value;
}

DataSetRow.prototype.getValue = function (key) {
    return this.form[key];
}

DataSetRow.prototype.initForm = function () {
    for (let propkey in this.columns) {
        this.form[propkey] = this.columns[propkey].getInitValue();
    }
}

export default DataSetRow;