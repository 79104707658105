<template>
    <div style="background: #ffffff">
        <!-- 头部 -->
        <hc-header @search="doSearch"/>
        <!-- 主体 -->
        <div class="content">
            <!--            <div class="sub-banner">-->
            <!--                <div class="w1200 sub-slogan">-->
            <!--                    <h2 class="sub-sen">product center</h2>-->
            <!--                    <h2 class="sub-stitle">产品中心</h2>-->
            <!--                    <p class="sub-sdesc">一流的研发实力/一流的生产设备/一流的试验检测设备/一流的质量控制水平</p>-->
            <!--                </div>-->
            <!--            </div>-->
            <!-- 产品系列 -->
            <product-list ref="productList"/>
        </div>
        <hc-footer/>
        <hc-sidenav/>
    </div>
</template>
<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import HcSidenav from "@/components/busi/sidenav/hc-sidenav.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import ProductList from "@/components/busi/product/product-list.vue";
import {isNotBlank} from "@/common/utils";

export default {
    name: "index",
    components: {ProductList, HcFooter, HcSidenav, HcHeader},
    methods: {
        doSearch(keyword) {
            if (this.$refs.productList) {
                this.$refs.productList.loadData(keyword);
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>