<template>
    <div class="hc">
        <div :class="{'cata-header':isScroll}">
            <hc-header/>
        </div>
        <div class="content w1200">
            <div class="cataMain">
                <h2 class="banner-title">service</h2>
                <h4 class="banner-subtitle">{{ $t('catalogue.slogan') }}</h4>
                <div class="cataSearch" :class="{'showPanel':isShow}">
                    <div class="search" @keyup.enter="search">
                        <div class="search-input-box">
                            <i class="iconfont search-icon">&#xe747;</i>
                            <input class="search-input" v-model="keyword"
                                   :placeholder="isLogin ? $t('catalogue.placeholder'): $t('catalogue.login2search')"
                                   :disabled="!isLogin"
                                   type="text"/>
                        </div>
                        <button class="search-btn" :disabled="!isLogin" @click="search">{{ $t('catalogue.search') }}</button>
                    </div>
                </div>
            </div>

            <!--      s搜索结果-->
            <div class="result-panel" v-if="isShow">
                <ul class="result">
                    <li class="result-item"
                        v-for="(item, index) in list"
                        :key="index"
                    >
                        <img class="file-icon" src="@/assets/icon/file.png"/>
                        <div class="result-left">
                            <span>{{ item.filename }}</span>
                            <span class="upload" @click="downloadAttachment(item.src)"><img src="@/assets/icon/upload.png"></span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <hc-footer/>
    </div>
</template>

<style scoped lang="scss">
.hc {
    width: 100%;
    background: url("@/assets/bg/search.png") no-repeat;
    background-size: 100%;
    height: 100vh;
}
</style>
<script>
import HcHeader from "@/components/busi/header/hc-header.vue";
import HcFooter from "@/components/busi/footer/hc-footer.vue";
import HcSidenav from "@/components/busi/sidenav/hc-sidenav.vue";
import {getPubAttachment} from "@/api/hc";
import Mixins from "@/common/core/mixins";

export default {
    name: " ",
    components: {HcFooter, HcSidenav, HcHeader},
    mixins: [Mixins],
    data() {
        return {
            keyword: null,
            list: [],
            isScroll: true
        }
    },
    computed: {
        isShow() {
            return this.list.length > 0;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        search() {
            this.doSearch()
                .then(list => {
                    this.list = [];
                    this.list = list;
                });
        },
        async doSearch() {
            const {list} = await getPubAttachment(this.keyword);
            return list;
        },
        handleScroll() {
            if (window.scrollY > 60) {
                this.isScroll = false;
            } else {
                this.isScroll = true;
            }
        },
        downloadAttachment(url) {
            console.log(url);
            window.open(url)
        }
    }
}
</script>