/**
 * 对象深克隆
 * @param obj 源对象
 * @param setEmpty2Null 将空对象设置为null
 * @returns 克隆后的对象
 */
export function deepClone(obj, setEmpty2Null = false) {
    if (obj === null || typeof obj !== 'object') return obj;
    let clone;
    if (obj instanceof Date) {
        clone = new Date(obj.getTime());
    } else if (obj instanceof RegExp) {
        clone = new RegExp(obj);
    } else if (obj instanceof Map) {
        clone = new Map(Array.from(obj, ([key, value]) => [key, deepClone(value)]));
    } else if (obj instanceof Set) {
        clone = new Set(Array.from(obj, value => deepClone(value)));
    } else if (Array.isArray(obj)) {
        clone = obj.map(value => deepClone(value));
    } else if (Object.prototype.toString.call(obj) === '[object Object]') {
        clone = Object.create(Object.getPrototypeOf(obj));
        for (const [key, value] of Object.entries(obj)) {
            clone[key] = deepClone(value);
        }
    } else {
        clone = Object.assign({}, obj);
    }
    return clone;
}


/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
    if (arguments.length === 0 || !time) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if ((typeof time === 'string')) {
            if ((/^[0-9]+$/.test(time))) {
                // support "1548221490638"
                time = parseInt(time);
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/');
            }
        }

        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (('' + time).length === 10) {
        time = parseInt(time) * 1000;
    } else {
        time = +time;
    }
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return '刚刚';
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
        return '1天前';
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        );
    }
}

export function accDiv(num1, num2) {
    let t1, t2, r1, r2;
    try {
        t1 = num1.toString().split('.')[1].length;
    } catch (e) {
        t1 = 0;
    }
    try {
        t2 = num2.toString().split(".")[1].length;
    } catch (e) {
        t2 = 0;
    }
    r1 = Number(num1.toString().replace(".", ""));
    r2 = Number(num2.toString().replace(".", ""));
    return (r1 / r2) * Math.pow(10, t2 - t1);
}

//乘法函数，用来得到精确的乘法结果
//说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
//调用：accMul(arg1,arg2)
//返回值：arg1乘以arg2的精确结果
export function accMul(arg1, arg2) {
    let m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length
    } catch (e) {
    }
    try {
        m += s2.split(".")[1].length
    } catch (e) {
    }
    let num1 = Number(s1.replace(".", ""));
    let num2 = Number(s2.replace(".", ""));
    if (isNaN(num1)) {
        return 0;
    }
    if (isNaN(num2)) {
        return 0;
    }
    return num1 * num2 / Math.pow(10, m);
}

//加法函数，用来得到精确的加法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
//调用：accAdd(arg1,arg2)
//返回值：arg1加上arg2的精确结果
export function accAdd(arg1, arg2) {
    let r1, r2, m;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (accMul(arg1, m) + accMul(arg2, m)) / m;
}

export function accSub(arg1, arg2) {
    let r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length
    } catch (e) {
        r1 = 0
    }
    try {
        r2 = arg2.toString().split(".")[1].length
    } catch (e) {
        r2 = 0
    }
    m = Math.pow(10, Math.max(r1, r2));
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

export function isEmptyObject(obj) {
    if (!obj) return false;
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    return Object.keys(obj).length === 0;
}

export function arrayContains(array, item) {
    for (let i in array) {
        if (array[i] == item) return true;
    }
    return false;
}

export function catchHandler(e, dialog = 'alert', msg = '出错啦') {
    console.trace('catchHandler')
    return new Promise((resolve, reject) => {
        if (e && e.cancel) {
            resolve(e);
            return;
        }
        if (Array.isArray(e) && e.length > 0) {
            const first = e[0];
            if (first.hasOwnProperty('field') && first.hasOwnProperty('message')) {
                // 表单校验信息，页面上显示，或者手动处理
                return;
            }
        }
        console.error(e);
        // uni.$loading.close();
        msg = getCatchMessage(e, msg);
        if ('alert' === dialog) {
            uni.$alert(msg).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            });
        } else if ('toast' === dialog) {
            uni.$toast(msg);
            resolve()
        }
    });
}

export function getCatchMessage(e, msg = '出错啦') {
    if (e && e.res && e.res.msg) {
        msg = e.res.msg;
    } else if (e && e.res && e.res.message) {
        msg = e.res.message;
    } else if (e && e.msg) {
        msg = e.msg;
    } else if (e && e.errMsg) {
        msg = e.errMsg;
    } else if (typeof e === "string") {
        msg = e;
    } else if (e instanceof Error) {
        msg = e.message;
    }
    return msg;
}

export function actionSheet(itemList, title, unselectMsg = '用户取消') {
    return new Promise((resolve, reject) => {
        uni.showActionSheet({
            itemList,
            success({tapIndex}) {
                resolve(tapIndex)
            },
            title,
            fail: function (res) {
                reject({
                    msg: unselectMsg
                })
            }
        });
    })
}

export function getInfo1(actions) {
    if (Array.isArray(actions)) {
        for (let i = 0; i < actions.length; i++) {
            const act = actions[i];
            if ('info1' == act.name && act.args && act.args[0]) {
                return act.args[0];
            }
        }
    }
    return null;
}

export function getParamsByKey(source, keys, setNull = false) {
    if (!source || !keys) {
        return null;
    }
    let arr = [];
    if (typeof keys === "string") {
        arr = keys.split(',');
    } else if (Array.isArray(keys)) {
        arr = keys;
    }
    const rs = {};
    arr.forEach(key => {
        let val = source[key];
        if ((val === null || typeof val === "undefined") && setNull) {
            val = '<NULL>'
        }
        rs[key] = val;
    });
    return rs;
}

export function parseConfig2Json(config) {
    if (!config) {
        return {};
    }
    if (typeof config === "object") {
        return config;
    } else if (typeof config === 'string') {
        if (config.startsWith('{')) {
            return JSON.parse(config);
        } else {
            const arr = config.split(";");
            const res = {}
            arr.forEach(item => {
                const separator = item.indexOf('=') > -1 ? '=' : ':';
                const b = item.split(separator);
                if (b.length === 2) {
                    res[b[0]] = b[1];
                }
            });
            return res;
        }
    }
    return {};
}

export function parseXJson(config) {
    if (!config) {
        return {};
    }
    let s = config;
    if (config[0] !== '{') {
        s = '{' + config + '}';
    }
    const array = s.split(/([,{}])(\w*):/g);
    let reg = /^[A-Za-z0-9.-_]+$/g;
    for (const key in array) {
        const element = array[key];
        if (reg.test(element)) {
            array[key] = `"${element}":`
        } else if (element === "'") {
            if (array[(key * 1) + 1] === '{') {
                array[key] = "";
            }
        } else if (element === ",") {
            const last = array[(key * 1) - 1]
            if (last.endsWith("}'")) {
                array[(key * 1) - 1] = last.substring(0, last.length - 1)
            }
        } else if (element.length >= 2) {
            if (element.indexOf("'") === 0) {
                const arr = element.split('');
                arr[0] = '"';
                if (element.endsWith("}'")) {
                    arr[arr.length - 1] = '';
                    for (let idx = array.length - 1; idx > 0; idx--) {
                        if (arr[idx] === "'") {
                            arr[idx] = '"';
                            break;
                        }
                    }
                } else {
                    arr[element.lastIndexOf("'")] = '"';
                }
                array[key] = arr.join('');
            } else if (element.indexOf('\\\'') > -1) {
                const arr = element.split('\\\'');
                array[key] = arr.join('"');
            }
        }
    }
    try {
        s = array.join('');
        return JSON.parse(s);
    } catch (e) {
        console.error(config);
        throw e;
    }
}

export function parseConfig2Array(value, separator = ',') {
    if (isBlank(value)) {
        return [];
    }
    if (Array.isArray(value)) {
        return value;
    } else {
        value = value + '';
        return value.split(separator);
    }
}

export function isBlank(val) {
    if (val == null || typeof val === 'undefined') {
        return true;
    }
    if (typeof val === 'string') {
        return val.trim() === ''
    }
    return false;
}

export function isNotBlank(val) {
    return !isBlank(val);
}

export function csvString(array) {
    if (!Array.isArray(array)) {
        return array;
    }
    let str = '';
    for (let i = 0; i < array.length; i++) {
        const s = array[i];
        if (isBlank(s)) {
            continue;
        }
        if (str.length > 0) {
            str += ','
        }
        str += s;
    }
    return str;
}

export function openPrintSave(path, download = false) {
    if (!(window && document)) { // TODO 非H5
        return;
    }
    if (isImageUrl(path)) {
        window.location.href = path;
    } else {
        if (download) {

        } else {
            window.location.href = `http://ow365.cn/?i=31516&furl=${encodeURIComponent(path)}`;
        }

    }
}

export function isImageUrl(url) {
    if (isBlank(url)) {
        return false;
    }
    let str = url.toLowerCase();
    let idx = str.indexOf("?");
    if (idx > 0) {
        str = str.substring(0, idx);
    }
    if (str.endsWith('.~gzip')) { // 压缩过的
        str = str.replaceAll('.~gzip', '');
    }
    if (str.endsWith('jpg') || str.endsWith('png') || str.endsWith('jpeg') || str.endsWith('webp') || str.endsWith('svg')) {
        return true;
    }
}

export function obj2bool(val) {
    if (!val) {
        return false;
    }
    return !((val + '') === '0' || (val + '') === 'false' || (val + '') === 'no');
}

export function obj2int(val, dft = null) {
    if (typeof val === 'undefined' || val === null) {
        return val;
    }
    const v = parseInt(String(val));
    if (isNaN(v)) {
        return (dft === null) ? null : dft;
    }
    return v;
}

export function formatDigits(value, n) {
    let f = Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
    let s = f.toString();
    let rs = s.indexOf('.');
    if (rs < 0) {
        s += '.';
    }
    for (let i = s.length - s.indexOf('.'); i <= n; i++) {
        s += "0";
    }
    return s;
}

/**
 * 替换宏
 * @param string
 * @param params
 * @returns {*}
 */
export function replaceMacro(string, params) {
    if (isBlank(string)) {
        return string;
    }
    if (!params) {
        return string;
    }
    let str = string;
    for (let key in params) {
        const val = params[key];
        if (typeof val === 'string' || typeof val === 'number') {
            str = str.replaceAll('${' + key + '}', val);
        }
    }
    return str;
}

export function getIgnoreCase(map, key) {
    for (let mapKey in map) {
        if (mapKey.toLowerCase() === key.toLowerCase()) {
            return map[mapKey];
        }
    }
    return null;
}