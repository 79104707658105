import request from "@/common/core/request";

export function orderInit(cartIds, fcode) {
    return request.post('/mall/order/init', {
        cartIds, fcode
    });
}

export function submitOrder(orderInfo) {
    return request.post('/mall/order/submit', orderInfo);
}
